import React, { useState, useRef, useEffect } from "react";
import { Rnd } from "react-rnd";
import Icon from "../Icon";
        
        const Sticker = ({ sticker, onUpdate, onDelete, onClick }) => {
            const [isHovered, setIsHovered] = useState(false);
            const [isRotating, setIsRotating] = useState(false);
            const [startAngleOffset, setStartAngleOffset] = useState(0);
        
            const stickerRef = useRef(null);
        
            const handleStickerClick = (e) => {
                e.stopPropagation();
                onClick();
            };
        
            const handleRotateMouseDown = (e) => {
                e.stopPropagation();
                e.preventDefault();
        
                setIsRotating(true);
        
                const rect = stickerRef.current.getBoundingClientRect();
                const center = {
                    x: rect.left + rect.width / 2,
                    y: rect.top + rect.height / 2,
                };
        
                const initialAngle = Math.atan2(
                    e.clientY - center.y,
                    e.clientX - center.x
                ) * (180 / Math.PI);
                setStartAngleOffset(initialAngle - sticker.rotation);
            };
        
            const handleMouseMove = (e) => {
                if (!isRotating) return;
        
                const rect = stickerRef.current.getBoundingClientRect();
                const center = {
                    x: rect.left + rect.width / 2,
                    y: rect.top + rect.height / 2,
                };
        
                const currentAngle = Math.atan2(
                    e.clientY - center.y,
                    e.clientX - center.x
                ) * (180 / Math.PI);
                const newRotation = currentAngle - startAngleOffset;
                onUpdate(sticker.id, { rotation: newRotation });
            };
        
            const handleMouseUp = () => {
                if (isRotating) setIsRotating(false);
            };
        
            useEffect(() => {
                if (isRotating) {
                    window.addEventListener("mousemove", handleMouseMove);
                    window.addEventListener("mouseup", handleMouseUp);
                    return () => {
                        window.removeEventListener("mousemove", handleMouseMove);
                        window.removeEventListener("mouseup", handleMouseUp);
                    };
                }
            }, [isRotating]);
        
            return (
                <Rnd
                    default={{
                        x: sticker.x,
                        y: sticker.y,
                        width: 100 * sticker.scale,
                        height: 100 * sticker.scale,
                    }}
                    bounds="parent"
                    onDragStop={(e, d) => onUpdate(sticker.id, { x: d.x, y: d.y })}
                    onResizeStop={(e, direction, ref, delta, position) => {
                        const newScale = parseFloat(ref.style.width) / 100;
                        onUpdate(sticker.id, {
                            scale: newScale,
                            x: position.x,
                            y: position.y,
                        });
                    }}
                    onClick={handleStickerClick}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{
                        backgroundColor: "transparent",
                        // We remove the border from here so that it's not fixed to the Rnd container
                    }}
                >
                    <div
                        ref={stickerRef}
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            transform: `rotate(${sticker.rotation}deg)`,
                            transformOrigin: "center center",
                            border: sticker.isFocus
                                ? "2.5px solid #823bf5"
                                : isHovered
                                ? "2.5px solid #823bf5"
                                : "1px dashed transparent",
                            boxSizing: "border-box",
                        }}
                    >
                        <img
                            src={sticker.url}
                            alt="sticker"
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "block",
                            }}
                        />
                        {isHovered && !isRotating && (
                            <button
                                style={{
                                    position: "absolute",
                                    top: -11,
                                    right: 15,
                                    background: "red",
                                    color: "white",
                                    borderRadius: "50%",
                                    border: "none",
                                    width: 20,
                                    height: 20,
                                    cursor: "pointer",
                                    zIndex: 2000,
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDelete(sticker.id);
                                }}
                            >
                                ✕
                            </button>
                        )}
        
                        {sticker.isFocus && !isRotating && (
                            <div
                                onMouseDown={handleRotateMouseDown}
                                style={{
                                    position: "absolute",
                                    top: "-40px",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    width: "20px",
                                    height: "20px",
                                    backgroundColor: "#823bf5",
                                    borderRadius: "50%",
                                    cursor: "grab",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "white",
                                    fontWeight: "bold",
                                    userSelect: "none",
                                    zIndex: 2000,
                                }}
                            >
                                <Icon name="rotate" src="/images/svg/rotate-option-svgrepo-com-not-clicked.svg"
                                />
                            </div>
                        )}
                        {sticker.isFocus && isRotating && (
                            <div
                            style={{
                                position: "absolute",
                                top: "-40px",
                                left: "50%",
                                transform: "translateX(-50%)",
                                width: "20px",
                                height: "20px",
                                backgroundColor: "transparent",
                                borderRadius: "50%",
                                cursor: "grab",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "#823bf5",
                                fontWeight: "bold",
                                userSelect: "none",
                                zIndex: 2000,
                                lineHeight: "10px",
                            }}
                        >
                            <Icon name="rotate" src="/images/svg/rotate-option-svgrepo-com-clicked.svg"/>
                        </div>
                            )}
        
                        {sticker.isFocus &&
                            ["top-left", "top-right", "bottom-left", "bottom-right"].map(
                                (corner) => (
                                    <div
                                        key={corner}
                                        style={{
                                            position: "absolute",
                                            [corner.split("-")[0]]: "-8px",
                                            [corner.split("-")[1]]: "-8px",
                                            width: "16px",
                                            height: "16px",
                                            backgroundColor: "#823bf5",
                                            borderRadius: "50%",
                                        }}
                                    />
                                )
                            )}
                    </div>
                </Rnd>
            );
        };
        
        export default Sticker;
        