import React, { useState, useRef, useEffect, use } from "react";
// Components
import Card from "../../components/Card";
import AlbumsMatrix from "../../components/AlbumsMatrix/AlbumsMatrix";
import cn from "classnames";
import { convertStringNumbersToNumbers } from "../../utils";
import styles from "./Albums.module.sass";
import InputMatrix from "../../components/InputMatrix/InputMatrix";
import Icon from "../../components/Icon";
import ZipCreator from "../../services/ZipCreatorService";
import Dropdown from "../../components/Dropdown";
import { fetchArtifactsFromLocalStorageByType } from "../../services/LocalStorageService";
import { toast } from "react-hot-toast";
import { useAuth } from "../../contexts/AuthContext";
import { useParams } from "react-router-dom";
import { useLoadingBar } from "../../contexts/LoadingBarContext";
import PromptBox from "../../components/PromptBox";
import ApiService from "../../services/ApiService";
import CustomTooltip from "../../components/CustomTooltip";
import TooltipData from "../../components/CustomTooltip/TooltipsData";
import { pointer } from "@testing-library/user-event/dist/cjs/pointer/index.js";
import { clearInputMatrixLocalStorage } from "../../components/InputMatrix/InputMatrix";

const Albums = () => {
  // const [imageUploadProps, setImageUploadProps] = useState({});
  const [contentVisible, setContentVisible] = useState(false);
  const [artifact, setArtifact] = useState(null);
  const [canGenerate, setCanGenerate] = useState(true);
  const { artifacts } = useAuth();
  // const [currentPrompt, setCurrentPrompt] = useState("");
  const [currentPrompt, setCurrentPrompt] = useState(() => {
    return JSON.parse(localStorage.getItem("currentPrompt")) ?? "";
  });
  const albumsPromptRef = useRef(null);
  const [textsList, setTextsList] = useState({});
  const [creationsList, setCreationsList] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,]);
  const [generalPrompt, setPrompt] = useState("");
  const [banInfo, setBanInfo] = useState({ isBanned: false, timeLeft: 0 });
  const [isDisabled, setIsDisabled] = useState(false);
  const inputRef = useRef(null);
  const [showCardsetPrompt, setShowCardsetPrompt] = useState(false);
  const [showImagesMatrix, setShowImagesMatrix] = useState(false);
  const [isDisabledList, setIsDisabledList] = useState(Array(9).fill(true));
  const [isAllowedRegenerateList, setIsAllowedRegenerateList] = useState(
    Array(9).fill(true)
  );
  const { progress, setProgress } = useLoadingBar();
  const [ArtifactNameForStyle, setArtifactNameForStyle] = useState(null);
  const [pollingStatus, setPollingStatus] = useState([]);
  const pollingIntervals = useRef({});
  const [lastGenerateTime, setLastGenerateTime] = useState(null);
  const [showResetAllButton, setShowResetButton] = useState(true);
  const [colorPaletteOptions, setColorPaletteOptions] = useState([]);
  const [selectedColorPaletteOption, setSelectedColorPaletteOption] = useState(null);
  const [settings, setSettings] = useState(null);
  const { organizationData } = useAuth();
  const [ReferencesOptions, setReferencesOptions] = useState([])
  const [isDownloadButtonVisible, setIsDownloadButtonVisible] = useState(false);
  const { artifactId } = useParams();
  const organizationName = organizationData["organization"];
  // const numOfCards = parseInt(organizationData["number_of_cards"]) || 9;
  const [numOfCards, setNumOfCards] = useState(9);
  useEffect(() => {
    async function fetchArtifact() {
      if (artifactId) {
        try {
          const ARTIFACT_ENDPOINT = `/get_artifact/${artifactId}`;
          setProgress(30);
          const artifactResponse = await ApiService.get(ARTIFACT_ENDPOINT);
          setProgress(60);
          setArtifact(artifactResponse.data.artifact);
          setSettings(
            convertStringNumbersToNumbers(
              artifactResponse.data.artifact.settings
            )
          );
          setProgress(100);
        } catch (error) {
          console.error("Error fetching artifact:", error);
          setProgress(0);
        }
      }
    }

    fetchArtifact();
  }, [artifactId]);

  useEffect(() => {
    const baseOptions = [
      ...new Set(
        organizationData.references
          .sort((a, b) => a.index - b.index) // Sort by index attribute
          .map((r) => r.reference_type)
      ),
    ];
    setReferencesOptions(baseOptions);
  }, [organizationData]);

  useEffect(() => {
    if (creationsList && creationsList.every(item => item === null))
    {
      console.log("creationsList is null");
      setShowResetButton(false);
    }
    if (textsList && Object.values(textsList).some(item => item !== "")) {
      console.log("At least one item in textsList is not empty");
      setShowResetButton(true);
    }
    
  }, [creationsList, textsList]);

  useEffect(() => {
    setNumOfCards(parseInt(artifact?.number_of_cards) || 9);
  }, [artifact]);

  useEffect(() => {
    setCreationsList(Array(numOfCards).fill(''));
    setIsDisabledList(Array(numOfCards).fill(true));
    setIsAllowedRegenerateList(Array(numOfCards).fill(true));
  }, [numOfCards]);
 

  useEffect(() => {
    const allNotNull =
      creationsList.length > 0 &&
      creationsList.every((item) => item) &&
      canGenerate;

    setIsDownloadButtonVisible(allNotNull);
  }, [creationsList, canGenerate]);
  
  // Load state from localStorage when component mounts
  useEffect(() => {
    const savedState = localStorage.getItem("AlbumsScreen");
    if (savedState) {
      const {
        contentVisible,
        artifact,
        creationsList, // Change this from recentCreations
        canGenerate,
        textsList,
        generalPrompt,
        banInfo,
        isDisabled,
        showCardsetPrompt,
        showImagesMatrix,
        isDisabledList,
        isAllowedRegenerateList,
        characterForCard9,
        pollingStatus,
        lastGenerateTime,
      } = JSON.parse(savedState);

      setContentVisible(contentVisible);
      setArtifact(artifact);
      setCanGenerate(canGenerate);
      setTextsList(textsList);
      setCreationsList(creationsList);
      setPrompt(generalPrompt);
      setBanInfo(banInfo);
      setIsDisabled(isDisabled);
      setShowCardsetPrompt(showCardsetPrompt);
      setShowImagesMatrix(showImagesMatrix);
      setIsDisabledList(isDisabledList);
      setIsAllowedRegenerateList(isAllowedRegenerateList);
      setArtifactNameForStyle(characterForCard9);
      setPollingStatus(pollingStatus || []);
      setLastGenerateTime(lastGenerateTime);
    }
  }, []);

  useEffect(() => {
    return () => {
      Object.values(pollingIntervals.current).forEach((interval) => {
        if (interval) clearInterval(interval);
      });
    };
  }, []);

  // Save state to localStorage when state changes
  useEffect(() => {
    const state = {
      contentVisible,
      artifact,
      creationsList,
      canGenerate,
      textsList,
      generalPrompt,
      banInfo,
      isDisabled,
      showCardsetPrompt,
      showImagesMatrix,
      isDisabledList,
      isAllowedRegenerateList,
      characterForCard9: ArtifactNameForStyle,
      pollingStatus,
      lastGenerateTime,
    };
    localStorage.setItem("AlbumsScreen", JSON.stringify(state));
  }, [
    contentVisible,
    artifact,
    creationsList,
    canGenerate,
    textsList,
    generalPrompt,
    banInfo,
    isDisabled,
    showCardsetPrompt,
    showImagesMatrix,
    isDisabledList,
    isAllowedRegenerateList,
    ArtifactNameForStyle,
    pollingStatus,
    lastGenerateTime,
  ]);

  useEffect(() => {
    if (lastGenerateTime) {
      const timer = setTimeout(() => {
        setShowResetButton(true);
      }, 3 * 60 * 1000);

      return () => clearTimeout(timer);
    }
  }, [lastGenerateTime]);

  useEffect(() => {
    return () => {
      Object.values(pollingIntervals.current).forEach(clearInterval);
    };
  }, []);

  // const clearImagesFromLocalStorage = () => {
  //   for (let i = 0; i < localStorage.length; i++) {
  //     const key = localStorage.key(i);
  //     if (key.startsWith('sketchImage-')) {
  //         localStorage.removeItem(key);
  //         i--; // Adjust index since localStorage length changes
  //     }
  //   }
  // };
  
  const updateDownloaded = async (creationId, creationSubId) => {
    console.log(
      "Updating download status for creation: ",
      creationId,
      creationSubId
    );
    
    try {
      // Simulate API call to update download status.
      ApiService.post(`/download_creation/${creationId}/${creationSubId}`);
    } catch (error) {
      console.error("Failed to toggle download status:", error);
    }
  };

  const onRegenerateClicked = async (i, j) => {
    console.log("i:", i, "j:", j);
    console.log("Regenerate clicked for index: ", i * 3 + j);
    const prompt = Object.values(textsList)[i * 3 + j];
    console.log("Prompt:", prompt);
    
    if (prompt.length < 3) {
      toast.error("The prompt should have at least 3 characters.");
      return;
    }

    const creation = creationsList[i * 3 + j];

    const idx = i * 3 + j;
    console.log("Index:", idx);
    
    const promptsArray = Object.values(textsList).map((item) =>
      typeof item === "string" ? { prompt: item } : item
    );
    console.log("Prompts array:", promptsArray);
    console.log("Prompts array[idx]:", promptsArray[idx]);
    
    const promptText = promptsArray[idx].prompt;
    const referenceType = promptsArray[idx].reference_type;
    const sketchImage = promptsArray[idx].sketch_image;
    console.table({ promptText, referenceType, sketchImage });
    
    setCreationsList((prevList) =>
      prevList.map((creation, index) => (index === idx ? "" : creation))
    );
    setIsDisabledList((prevList) =>
      prevList.map((state, index) => (index === idx ? true : state))
    );
    setIsAllowedRegenerateList((prevList) =>
      prevList.map((state, index) => (index === idx ? false : state))
    );

    const requestData = {
      // prompt: prompt,
      prompt: promptText,
      reference_type: referenceType,
      sketch_image: sketchImage,
      creation_id: creation.creation_id,
      creation_sub_id: creation.creation_sub_id,
      artifact_id: creation.artifact_id,
      enrich_prompt_by_chatgpt: false,
      is_card: true,
      artifact_display_name: artifact.display_name,
    };
    console.log("Request data: ", requestData);
    
    if (selectedColorPaletteOption) {
      requestData.master_color = selectedColorPaletteOption.value[0];
    }
    try {
      const statusResponse = await ApiService.post(
        `/start_generation`,
        requestData
      );
      const creationId = statusResponse.creation_id;
      console.log(statusResponse.creation_id);
      
      toast.promise(
        new Promise((resolve) => {
          setTimeout(() => {
            console.log("Starting polling for index: ", i * 3 + j);
            setPollingStatus((prevStatus) => [
              ...prevStatus,
              { taskId: creationId, idx },
            ]);
            pollTaskStatus(creationId, creationId, i * 3 + j);
            resolve();
          }, 10000); // 10 seconds delay
        }),
        {
          loading: "Preparing regeneration...",
          success: "Regeneration started successfully!",
          error: "Failed to start regeneration. Please try again later.",
        }
      );
    } catch (error) {
      console.error("Regeneration error:", error);
      toast.error(
        "Failed to regenerate the collectible. Please try again later."
      );
    }
  };

  const DownloadCardset = async () => {
    if (creationsList.some((image) => !image)) {
      console.warn("No images available to download.");
      return;
    }

    const generateUniqueNames = (creationsList) => {
      const promptCounts = {};
      return creationsList
        .map((creation, index) => {
          let baseName = Object.keys(textsList)[index].replace(/ /g, "_"); // Replace spaces with underscores
          if (promptCounts[baseName]) {
            promptCounts[baseName]++;
            baseName += `_${promptCounts[baseName]}`;
          } else {
            // If the prompt is seen for the first time, initialize the count
            promptCounts[baseName] = 1;
          }
          return {
            name: `${baseName}.png`,
            url: creation.result_image_url[0],
          };
        })
        .filter((file) => file.url); // Ensure no empty URLs are included
    };

    const files = generateUniqueNames(creationsList);
    // const files = generateUniqueNames(creationsList, Object.values(textsList));

    console.log("files", files);
    
    creationsList.map((creation) =>
      updateDownloaded(creation.creation_id, creation.creation_sub_id)
    );

    console.log("creationsList.length", creationsList.length);

    toast
      .promise(
        ZipCreator.createZipFile(
          files,
          // generalPrompt.replace(/ /g, "_") + ".zip"
          "Album"+localStorage.getItem("currentPrompt").replace(/ /g, "_")
        ),
        {
          loading: "Preparing for downloading...",
          success: "Download was successful.",
          error: "Failed to create zip file. Please try again later.",
        }
      )
      .catch((error) => {
        console.error("Failed to create zip file:", error);
      });
  };

  const toggleLikeStatus = (creationId, creationSubId) => {
    const index = creationsList.findIndex(
      (c) => c.creation_id === creationId && c.creation_sub_id === creationSubId
    );
    if (index === -1) {
      toast.error("Creation not found. Please try again later.");
      return;
    }

    const updatedStatus = !creationsList[index].liked;
    const updatedCreations = creationsList.map((creation, i) => {
      if (i === index) {
        return { ...creation, liked: updatedStatus };
      }
      return creation;
    });

    toast
      .promise(
        ApiService.post(
          `/like_or_dislike_creation/${creationId}/${creationSubId}`,
          { liked: updatedStatus }
        ),
        {
          loading: "Updating like status...",
          success: () => {
            setCreationsList(updatedCreations);
            return updatedStatus
              ? "Liked successfully!"
              : "Unliked successfully!";
          },
          error: "Failed to update like status. Please try again later.",
        }
      )
      .catch((error) => {
        console.error(error);
      });
  };

  const pollTaskStatus = async (taskId, uniqueId, idx) => {
    if (pollingIntervals.current[taskId]) {
      clearInterval(pollingIntervals.current[taskId]);
    }
  
  
    pollingIntervals.current[taskId] = setInterval(async () => {
      try {
        const statusResponse = await ApiService.post("/check_status", [
          { creation_id: taskId },
        ]);
  
        const result = statusResponse?.results?.[0];
        if (!result) {
          console.error("No result found in status response");
          clearInterval(pollingIntervals.current[taskId]);
          delete pollingIntervals.current[taskId];
          return;
        }
  
  
        if (result?.status === "completed") {
          clearInterval(pollingIntervals.current[taskId]);
          delete pollingIntervals.current[taskId];
          setPollingStatus((prevStatus) =>
            prevStatus.filter((status) => status.taskId !== taskId)
          );
  
          // -- We only get the single updated creation for regeneration --
          // If multiple creations are returned, match by creation_sub_id.
          // This approach ensures we only replace the correct item in the array.
          if (idx != null) {
            console.log("Updating image at index: ", idx);
            // Single regeneration flow:
            const updatedCreation = Array.isArray(result.creations)
              ? result.creations.find(
                  (c) => parseInt(c.previous_creation_sub_id) === idx
                )
              : null;
            console.log("result.creations", result.creations);
            console.log("Updated creation:", updatedCreation);
            if (updatedCreation) {
              setCreationsList((prevList) =>
                prevList.map((creation, index) =>
                  index === idx ? updatedCreation : creation
                )
              );
              setIsDisabledList((prev) =>
                prev.map((disabled, i) => (i === idx ? false : disabled))
              );
              setIsAllowedRegenerateList((prev) =>
                prev.map((allowed, i) => (i === idx ? true : allowed))
              );
            }
          } else {
            // Full generation flow (Generate All)
            if (result.creations && result.creations.length > 0) {
              setCreationsList(result.creations);
              setIsDisabledList(Array(numOfCards).fill(false));
              setIsAllowedRegenerateList(Array(numOfCards).fill(true));
            }
          }
  
          setCanGenerate(true);
        } else if (result?.status === "pending") {
          // If you want to show partial updates while it's pending,
          // you can handle them here (optional)
          // e.g., setCreationsList(result.creations)
        } else if (result?.status === "failed") {
          clearInterval(pollingIntervals.current[taskId]);
          delete pollingIntervals.current[taskId];
          setPollingStatus((prevStatus) =>
            prevStatus.filter((status) => status.taskId !== taskId)
          );
          toast.error("Failed to generate creations. Please try again later.");
          setCanGenerate(true);
  
          // Remove failed creation(s)
          setCreationsList((prevCreations) =>
            prevCreations.filter(
              (creation) => !creation?.creation_id?.startsWith(uniqueId)
            )
          );
        }
      } catch (error) {
        console.error("Error polling task status:", error);
        clearInterval(pollingIntervals.current[taskId]);
        delete pollingIntervals.current[taskId];
        toast.error("Error polling task status. Please try again later.");
        setCanGenerate(true);
      }
    }, 2000);
  };


  const generateCreations = async ({
    masterColor,
  }) => {
    // if (!ArtifactNameForStyle && ["INNPLAY-0c0dc5"].includes(organizationName)) {
    //   toast.error("Please select an inspiration artifact.");
    //   return;
    // }
    console.log("GENERATE CREATIONS");
    const ArtifactIdForStyle = artifacts.filter(
      (artifact) => artifact.display_name === ArtifactNameForStyle
    )[0]?.artifact_id;
  
    setIsAllowedRegenerateList(Array(numOfCards).fill(false));
    setCanGenerate(false);
  
    const promptsArray = Object.values(textsList).map((item) =>
      typeof item === "string" ? { prompt: item } : item
    );
    console.log("Prompts array:", promptsArray);
  
    console.log("artifact_for_style_id:", ArtifactIdForStyle);
    const requestData = {
      input_data: promptsArray,
      artifact_id: artifact?.artifact_id,
      master_color: masterColor,
      artifact_for_style_id: ArtifactIdForStyle,
    };
  
    console.log("requestData:\n", requestData);
  
    const promise = new Promise(async (resolve, reject) => {
      try {
        // setCreationsList(["", "", "", "", "", "", "", "", ""]);
        setCreationsList(Array(numOfCards).fill(""));
        console.log("Request data: ", requestData);
        console.log("creations list%: ", creationsList);
        
        const response = await ApiService.post(
          // "/start_generate_albums_after_receiving_prompts",
          "/start_generation",
          requestData
        );
        if (response.error) {
          throw new Error(response.error);
        }
  
        const creationId = response.creation_id;
        setShowImagesMatrix(true);
        setShowCardsetPrompt(true);
        setLastGenerateTime(Date.now());
        setShowResetButton(true);
  
        // Start polling for task status
        setTimeout(() => {
          setPollingStatus([{ taskId: creationId, idx: null }]);
          pollTaskStatus(creationId, creationId);
        }, 12000);
  
        resolve();
      } catch (error) {
        console.error(error);
        setCanGenerate(true);
        reject(error);
      }
    });
  
    toast.promise(promise, {
      loading: "Getting ready...",
      success: "The album is about to be ready!",
      error: "Failed to initiate creation generation. Please try again later.",
    });
  
    return promise;
  };

  const createDictOfThemesAndPrompts = (response, ArtifactNameForStyle) => {
    const combinedDict = {};
    const headlineCount = {};
    console.log("RESPONSE createDictOfThemesAndPrompts" , response);
      console.log("response:", "ORGANIZATION NAME:",organizationName, response);
      
      // response.creations_names.forEach((name, index) => {
      //   combinedDict[name] = response.prompts[index];
      // });
      response.creations_names.forEach((name, index) => {
        const uniqueName = headlineCount[name]
          ? `${name}_${headlineCount[name]}`
          : name;
        headlineCount[name] = (headlineCount[name] || 0) + 1;
        combinedDict[uniqueName] = response.prompts[index];
      });
    console.log("FINAL Combined dict: ", combinedDict);
    return combinedDict;
  };

  const generateIdeation = async ({
    prompt,
    creationId = null,
    masterColor,
  }) => {
    // resetAll();
    
    if (prompt.length < 3) {
      toast.error("The prompt should have at least 3 characters.");
      return;
    }
    let artifactNameForStyle = ArtifactNameForStyle;

    if (!ArtifactNameForStyle && artifact?.prompt_box_settings["select_character"]) {
      const firstOption = artifacts
        .filter((artifact) => artifact.type === "character")
        .map((artifact) => artifact.display_name)[0];
    
      if (firstOption) {
        artifactNameForStyle = firstOption;
        setArtifactNameForStyle(firstOption);
        console.log("ArtifactNameForStyle was automatically set to:", firstOption);
      } else {
        toast.error("No available characters to select. Please contact support.");
        return;
      }
    }
  
    const ArtifactIdForStyle = artifacts.filter(
      (artifact) => artifact.display_name === artifactNameForStyle
    )[0]?.artifact_id;
    
    localStorage.setItem("currentPrompt", JSON.stringify(prompt));

    setIsAllowedRegenerateList(Array(numOfCards).fill(false));
    setCanGenerate(false);
  
    const requestData = {
      prompt: prompt,
      artifact_id: artifact?.artifact_id,
      master_color: masterColor,
      artifact_for_style_id: ArtifactIdForStyle,
      number_of_cards: numOfCards,
    };
    
    const timestamp = Date.now(); // Create a unique timestamp for the session
  
    const promise = new Promise(async (resolve, reject) => {
      try {
        // setCreationsList(["", "", "", "", "", "", "", "", ""]);
        setCreationsList(Array(numOfCards).fill(""));
        
        const response = await ApiService.post("/get_ideations", requestData);
        if (response.error) {
          throw new Error(response.error);
        }
        setTextsList(() =>
          createDictOfThemesAndPrompts(response, ArtifactNameForStyle)
        );
        setShowCardsetPrompt(true);
        setShowResetButton(true);
  
        setTimeout(() => {
          setPollingStatus([{ taskId: creationId, idx: null }]);
          pollTaskStatus(creationId, creationId); // Starts polling after a 40 seconds delay.
        }, 12000);
  
        resolve();
      } catch (error) {
        console.error(error);
        setCanGenerate(true); // Reset the state to allow the user to try again
        reject(error);
      }
    });
  
    toast.promise(promise, {
      loading: "Preparing your prompts...",
      success: "The prompts are ready!",
      error: "Failed to initiate creation generation. Please try again later.",
    });
  
    return promise;
  };
  
  const resetAll = () => {
    setContentVisible(false);
    setCreationsList([]); // Change this from setRecentCreations([])
    setCanGenerate(true);
    setTextsList(["", "", "", "", "", "", "", "", ""]);
    localStorage.removeItem("currentPrompt");
    setPrompt("");
    setCurrentPrompt("");
    setBanInfo({ isBanned: false, timeLeft: 0 });
    setIsDisabled(false);
    setShowCardsetPrompt(false);
    setShowImagesMatrix(false);
    setIsDisabledList(Array(numOfCards).fill(true));
    setIsAllowedRegenerateList(Array(numOfCards).fill(true));
    setPollingStatus([]);
    setShowResetButton(false);
    setLastGenerateTime(null);
    clearInputMatrixLocalStorage();
    localStorage.removeItem("AlbumsScreen");
    // clearImagesFromLocalStorage();
    // setArtifactNameForStyle(null);
  };

  return (
    <div className={`${styles.shop} ${contentVisible ? styles.fadeIn : ""}`}>
      <div className={styles.background}>
        <img src={organizationData?.app_bg} alt="Background" />
      </div>

      <Card className={styles.card}>
        <h1>{artifact?.display_name}</h1>
        <div className={styles.profile} style={{ textAlign: "center"}}>
          <div
            className={styles.details}
            style={{ display: "flex", justifyContent: "center",}}
          >
            <div className={styles.wrap}>
              <div className={styles.inputButtonContainer}>
                <PromptBox
                  // canGenerate={canGenerate}
                  canGenerate={true}
                  artifact={artifact}
                  OnGenerateClicked={generateIdeation}
                  ArtifactNameForStyle={ArtifactNameForStyle}
                  setArtifactNameForStyle={setArtifactNameForStyle}
                  // showCardsetPrompt={showCardsetPrompt}
                  onPromptChange={setCurrentPrompt}
                  isAlbums={true}
                />
              </div>
            </div>
          </div>
          <br></br>
          {showCardsetPrompt && (
            <>
            <CustomTooltip title={TooltipData["RegenerateIdeation"]} placement="top">
              <button
              className={cn("button-small", styles.resetButton)}
              style={{
                position: 'absolute',
                height: '43px',
                backgroundColor: "#737370",
                fontSize: "16px",
                zIndex: "5",
                transform: 'translate(445%, -226%)'
              }}
              onClick={() => generateIdeation({prompt: currentPrompt})}>
                <Icon name="repeat" size="25"/>
              </button>
            </CustomTooltip>
            <div>
              {/* Card Set: */}
              <InputMatrix
                isDisabledList={isDisabledList}
                textsList={textsList}
                setTextsList={setTextsList}
                isAllowedRegenerateList={isAllowedRegenerateList}
                regenerateCreation={onRegenerateClicked}
                artifact={artifact}
                organizationData={organizationData}
                ReferencesOptions={ReferencesOptions}
                // onRegenerateClicked={onRegenerateClicked}
                // setImageUploadProps={setImageUploadProps}
                />
            </div>
            {(!(showImagesMatrix && creationsList.every(item => item !== "")))&&
              <button
                className={cn("button-small")}
                style={{ marginLeft: "-15px", height: '47px',backgroundColor: "#823bf5", marginTop: "15px", marginBottom: "-8px", fontSize: "18px", pointer: "pointer"}}
                onClick={generateCreations}
              >
                Generate All
              </button>}
          </>
          )}
          <center>
            {showImagesMatrix && settings && (
              <AlbumsMatrix
                creations={creationsList}
                organizationName={organizationName}
                toggleLikeStatus={toggleLikeStatus}
                regenerateCreation={onRegenerateClicked}
                inputs={textsList}
                settings={settings}
                artifact={artifact}
              />
            )}
            <br></br>
            {isDownloadButtonVisible && (
              <button className={cn("button-small")} onClick={DownloadCardset}
              style={{marginRight: "25px"}}>
                <div>
                  <Icon name="download" size="25" />
                  <span
                    className={styles.download}
                    style={{
                      pointerEvents: "auto",
                      width: "100%",
                      fontSize: "15px",
                    }}
                  >
                    Download All
                  </span>
                </div>
              </button>
            )}
            {showResetAllButton && (
              <button
                className={cn("button-small",)}
                style={{ marginLeft: "-15px", backgroundColor: "red", marginTop: "-7px", fontSize: "15px", height: '37px'}}
                onClick={resetAll}
              >
                Reset All
              </button>
            )}
          </center>
        </div>
      </Card>
    </div>
  );
};

export default Albums;