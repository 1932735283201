    import React, { useState, useMemo, useEffect } from "react";

    function NestedCheckboxTree({ onSelectionChange, checkedValuesProp = [], filterKey, treeData, organization}) {
    // Example data
    // const treeData = [
    //     {
    //     label: "Assets",
    //     value: "asset",
    //     children: [
    //         {
    //         label: "Backgrounds",
    //         value: "Background",
    //         },
    //         {
    //         label: "Characters",
    //         value: "character",
    //         children: [
    //             { label: "Donna the Dear", value: "Donna the Dear" },
    //             { label: "Danny the Rabbit", value: "Danny the Rabbit" },
    //         ],
    //         },
    //         {
    //         label: "Items",
    //         value: "Item",
    //         }
    //     ],
    //     },
    //     {
    //     label: "Offers",
    //     value: "offer",
    //     children: [
    //         {
    //         label: "Personal Offer 6",
    //         value: "Personal Offer 6",
    //         },
    //         {
    //         label: "Triple Rolling Offer",
    //         value: "Triple Rolling Offer",
    //         },
    //     ],
    //     },
    //     {
    //     label: "Features",
    //     value: "feature",
    //     children: [
    //         {
    //         label: "Quest",
    //         value: "quest",
    //         },
    //     ],
    //     },
    //     {
    //     label: "Albums",
    //     value: "album",
    //     },
    // ];

    const [searchText, setSearchText] = useState("");
    const [expandedNodes, setExpandedNodes] = useState({});
    const [checkedValues, setCheckedValues] = useState(checkedValuesProp);
    
    useEffect(() => {
        setCheckedValues(checkedValuesProp);
        // console.log("checkedValuesProp", checkedValuesProp);
    }, [checkedValuesProp]);

    const getAllDescendants = (node) => {
        let values = [node.value];
        if (node.children) {
        node.children.forEach((child) => {
            values = [...values, ...getAllDescendants(child)];
        });
        }
        return values;
    };

    const findNodeByValue = (nodes, value) => {
        for (const node of nodes) {
        if (node.value === value) {
            return node;
        }
        if (node.children) {
            const match = findNodeByValue(node.children, value);
            if (match) return match;
        }
        }
        return null;
    };

    const toggleExpand = (value) => {
        setExpandedNodes((prev) => ({
        ...prev,
        [value]: !prev[value],
        }));
    };

    const toggleCheck = (value) => {
        setCheckedValues((prev) => {
        const alreadyChecked = prev.includes(value);
        const node = findNodeByValue(treeData, value);
        if (!node) return prev; // if somehow not found

        // Get all descendant values
        const descendants = getAllDescendants(node);

        if (alreadyChecked) {
            // UNCHECK => remove node + all descendants
            return prev.filter((val) => !descendants.includes(val));
        } else {
            // CHECK => add node + all descendants
            // (combine old list + new descendants, but remove duplicates)
            const combined = [...prev, ...descendants];
            return Array.from(new Set(combined)); // unique
        }
        });
    };

    const filterTree = (nodes, query) => {
        console.log("nodes", nodes);
        console.log("query", query);
        if (!query) return nodes; // no search => no filtering
        const lowerQuery = query.toLowerCase();

        return nodes
        .map((node) => {
            const labelMatch = node.label.toLowerCase().includes(lowerQuery);

            // If node has children, recursively filter them
            let filteredChildren = [];
            if (node.children) {
            filteredChildren = filterTree(node.children, query);
            }

            // Keep the node if it or any of its children match
            if (labelMatch || filteredChildren.length > 0) {
            return {
                ...node,
                children: filteredChildren,
            };
            }
            return null;
        })
        .filter(Boolean);
    };

    const filteredData = useMemo(
        () => filterTree(treeData, searchText),
        [treeData, searchText]
    );

    const renderNodes = (nodes, level = 0) => {
        return (
        <ul style={{ listStyleType: "none", marginLeft: level * 20 }}>
            {nodes.map((node) => {
            const hasChildren = node.children && node.children.length > 0;
            const isExpanded = expandedNodes[node.value] === true;
            const isChecked = checkedValues.includes(node.value);

            return (
                <li key={node.value} style={{ margin: "8px 4px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {hasChildren ? (
                    <span
                        onClick={() => toggleExpand(node.value)}
                        style={{
                        cursor: "pointer",
                        display: "inline-block",
                        width: "12px",
                        }}
                    >
                        {isExpanded ? "▾" : "▸"}
                    </span>
                    ) : (
                    // If no children, align by using an empty 16px span
                    <span style={{ display: "inline-block", width: "12px" }} />
                    )}

                    {/* Checkbox */}
                    <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => toggleCheck(node.value)}
                    style={{ // Remove the OS-level checkbox styling
                        WebkitAppearance: "none", 
                        MozAppearance: "none",
                        appearance: "none",
                        width: "20px",
                        height: "20px",
                        borderRadius: "4px",
                        border: isChecked ? "1px solid #823bf5" : "1px solid #ccc",
                        backgroundColor: isChecked ? "#823bf5" : "#fff",
                        cursor: "pointer",
                        margin: "4px 8px",
                    }}
                    />

                    {/* Label */}
                    <span style={{fontSize: "18px", fontWeight: "normal"}}>{node.label}</span>
                </div>

                {/* Render children if expanded */}
                {hasChildren && isExpanded && renderNodes(node.children, level + 1)}
                </li>
            );
            })}
        </ul>
        );
    };

    const [prevCheckedValues, setPrevCheckedValues] = useState(checkedValues);

    useEffect(() => {
    // Check if new and old are actually different
    // e.g. compare array lengths or do a shallow compare
    const hasChanged = checkedValues.length !== prevCheckedValues.length
        || !checkedValues.every((val) => prevCheckedValues.includes(val));

    if (hasChanged && onSelectionChange) {
        onSelectionChange(filterKey, checkedValues);
        setPrevCheckedValues(checkedValues);
    }
    }, [checkedValues, prevCheckedValues, onSelectionChange, filterKey]);    

    return (
        <div
        style={{
            width: "100%",
            maxWidth: 500, 
            border: "1px solid #ccc",
            borderRadius: "8px",
            backgroundColor: "#fff",
        }}
        >
        {/* Search box at top (100% of the container’s width) */}
        <div style={{ marginBottom: "5px" }}>
            <input
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{
                fontSize: "16px",
                width: "100%",
                height: "37px",
                padding: "6px",
                boxSizing: "border-box",
                borderRadius: "8px 8px 0px 0px",
                border: "none",
                borderBottom: "1px solid #ccc",
            }}
            />
        </div>

        {/* Scrollable tree area */}
        <div style={{ maxHeight: "500px", overflow: "auto" }}>
            {filteredData.length === 0 ? (
            <p style={{ marginLeft: '6px'}}>No matches found</p>
            ) : (
            renderNodes(filteredData)
            )}
        </div>
        </div>
    );
    }

    export default NestedCheckboxTree;
