import axios from "axios";
import ApiService from "./ApiService";
    const BASE_URL = "http://localhost:5000";

    const LoggerService = {
    logEvent: async (level, message, details = {}) => {
        try {
        const logData = {
            level, // "info", "error", "warning", etc.
            message,
            details,
            timestamp: new Date().toISOString(),
        };
        
        // Send the log data to the backend
        // await axios.post(`${BASE_URL}/log`, logData);
        ApiService.post("/log", logData);
        } catch (error) {
        console.error("Failed to send log:", error);
        }
    },
    };

    export default LoggerService;