import React, { useState, useEffect } from "react";
import Card from "../../components/Card";
import styles from "./GenerateOfferScreen3.module.sass";
import { useLocation } from "react-router-dom";
import Profile from "./Profile/";
import cn from "classnames";
import Icon from "../../components/Icon";
import { toast } from "react-hot-toast";
import ZipCreator from "../../services/ZipCreatorService";
import GenerationService from "../../services/GenerationService";
import { useAuth } from "../../contexts/AuthContext";
import CreationCard from "../../components/Product";
import { useParams } from "react-router-dom";
import { useModalPreview } from "../../contexts/ModalPreviewContext";
import ApiService from "../../services/ApiService";
import { tileToIndexMapping } from '../../consts';


const finalQuestArtifactId = "dc055bde-9792-479e-934c-43ee19f825c6";
const tournamentLeaderboardArtifactId = "f52af31b-1d42-4592-9441-58656a220d73";
const tournamentBuyinArtifactId = "760c10df-ea70-4888-9f3f-e8c0c19f0994";
const innplayBackgroundArtifactId = "c4c0c88c-f549-438b-bffb-7b3bb0046827";
const innplayFinalArtifactId = "dc055bde-9792-479e-934c-43ee19f825c6";
const DicesArtifactsIds = [
  "188ce5b3-c53c-4a6e-995f-e4e6c15e682d",
  "260af4c8-ab2b-4c3e-8c22-9114061d3368",
];

const GenerateOfferScreen3 = () => {
  const { openModal } = useModalPreview();
  const [creations, setCreations] = useState([]);
  const [paginatedCreations, setPaginatedCreations] = useState([]);
  const [filteredCreations, setFilteredCreations] = useState([]);
  const [generationErrorMessage, setGenerationErrorMessage] = useState("");
  const [selectedItems, setSelectedItems] = useState({
    "droppable-1": [{ is_place_holder: true }],
    "droppable-2": [{ is_place_holder: true }],
  });
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [artifact, setArtifact] = useState(null);
  const [subArtifacts, setSubArtifacts] = useState([]);
  const [contentVisible, setContentVisible] = useState(true);
  const location = useLocation();
  const [canGenerate, setCanGenerate] = useState(true);
  const [loading, setLoading] = useState(true);
  const isNotSimple = true;
  const { organizationData } = useAuth();
  const [baseCreation, setBaseCreation] = useState(
    location.state?.baseCreation
  );
  const [currentViewingItemIndex, setCurrentViewingItemIndex] = useState();
  const { artifactId, creationType } = useParams();
  const [selectedSubArtifact, setSelectedSubArtifact] = useState(null);
  const [selectedSubArtifactIndex, setSelectedSubArtifactIndex] = useState();
  
  useEffect(() => {
    setSelectedSubArtifact(subArtifacts[selectedSubArtifactIndex]);
  }, [selectedSubArtifactIndex]);

  const toggleSubArtifact = (index) => {
    setSelectedSubArtifactIndex(index);
  };

const organization = organizationData.organization
console.log("organization",organization)

  const handleDownload = async () => {
    const files = [
      {
        name: `decoy_1.png`,
        url: selectedItems["droppable-1"][0].result_image_url[0],
      },
      {
        name: `decoy_2.png`,
        url: selectedItems["droppable-2"][0].result_image_url[0],
      },
    ];

    await ZipCreator.createZipFile(selectedItems, "Quest");

    try {
      await ApiService.post(
        `/download_creation/${selectedItems[0].creation_id}/${selectedItems[0].creation_sub_id}`
      );
      await ApiService.post(
        `/download_creation/${selectedItems[1].creation_id}/${selectedItems[1].creation_sub_id}`
      );
    } catch (error) {
      console.error("Failed to download:", error);
    }
  };

  const onDownload = async (creation) => {
    // const files = Object.values(selectedItems)
    //   .flat()
    //   .filter((item) => item && item.result_image_url) // Filters out any empty or falsy items
    //   .map((item, index) => ({
    //     name: `${item.creation_id}_${item.creation_sub_id}.png`,
    //     url: item.result_image_url[0],
    //   }));
    let onlyRoads = false;
    let itemsToProcess = selectedItems;
    console.log("Selected Items", selectedItems);
    if (!selectedItems[0]) {
      itemsToProcess = { 1: selectedItems[1] };
      onlyRoads = true;
    }
    console.log("itemsToProcess", itemsToProcess);
    console.log("onlyRoads", onlyRoads);

    const files = Object.entries(selectedItems)
    .flatMap(([key, value], groupIndex) => {
      if (Array.isArray(value)) {
        return value
          .filter((item) => item && item.result_image_url) // Filters valid items
          .map((item) => {
            if ((groupIndex !== 1 && !onlyRoads)) {
              // Default logic
              return {
                name: `${item.creation_id}_${item.creation_sub_id}.png`,
                url: item.result_image_url[0],
              };
            } else if (groupIndex === 1 || onlyRoads) {
              console.log("onlyRoads1", onlyRoads);
              // Logic for groupIndex 1
            //   const i = tileToIndexMapping[numberOfTilesToDisplay];
            //   if (i !== undefined && item.result_image_url[i]) {
            //     return {
            //       name: `${item.creation_id}_${item.creation_sub_id}.png`,
            //       url: item.result_image_url[i],
            //     };
            //   }
            // }
            return item.result_image_url.map((url, index) => ({
              name: `${item.creation_id}_${item.creation_sub_id}.${index}_tiles.png`,
              url,
            }));
          }
            return null;
          });
      }

      if (typeof value === "object" && value.result_image_url) {
        if ((groupIndex !== 1 && !onlyRoads)) {
          // Default logic
          return [
            {
              name: `${value.creation_id}_${value.creation_sub_id}.png`,
              url: value.result_image_url[0],
            },
          ];
        } else if (groupIndex === 1 || onlyRoads) {
          console.log("onlyRoads2", onlyRoads);

          // Logic for groupIndex 1
          // const i = tileToIndexMapping[numberOfTilesToDisplay];
          // if (i !== undefined && value.result_image_url[i]) {
          //   return [
          //     {
          //       name: `${value.creation_id}_${value.creation_sub_id}.png`,
          //       url: value.result_image_url[i],
          //     },
          //   ];
          // }
          return value.result_image_url.map((url, index) => ({
            name: `${value.creation_id}_${value.creation_sub_id}.${index}_tiles.png`,
            url,
          }));
        }
      }

      return []; // Skip invalid entries
    })
    .filter((file) => file !== null); // Remove null entries

  console.log("FILES\n", files);
  
  const downloadAllFiles = async () => {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    for (const file of files) {
      if (file && file.url && file.name) {
        console.log("Downloading file:", file);
        await downloadFile(file.url, file.name);
        await delay(500); // Adding a small delay to ensure browser processes each download
      }
    }
  };

  await toast.promise(
    downloadAllFiles(),
    {
      loading: "Downloading files...",
      success: "All files downloaded successfully!",
      error: "Failed to download files. Please try again later.",
    }
  );

    // Update the backend for each selected item
  try {
    await Promise.all(
      Object.values(selectedItems).map(async (item) => {
        if (item && item.creation_id && item.creation_sub_id) {
          try {
            await ApiService.post(
              `/download_creation/${item.creation_id}/${item.creation_sub_id}`
            );
          } catch (error) {
            console.error(
              `Failed to update download for item ${item.creation_id}:`,
              error
            );
          }
        }
      })
    );
  } catch (error) {
    console.error("Error updating backend after download:", error);
  }
};

const downloadFile = async (url, filename) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

  const [activeTileKey, setActiveTileKey] = useState(null);
  const [numberOfTilesToDisplay, setNumberOfTilesToDisplay] = useState(null);
  useEffect(() => {
    console.log("number of tiles to display:", numberOfTilesToDisplay);
  }, [numberOfTilesToDisplay]);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const itemsLimit = 9;

  const toggleLikeStatus = async (creationId, creationSubId) => {
    const index = creations.findIndex(
      (c) => c.creation_id === creationId && c.creation_sub_id === creationSubId
    );
    if (index === -1) {
      toast.error("Creation not found. Please try again later.");
      return;
    }

    const updatedStatus = !creations[index].liked;
    const updatedCreations = creations.map((creation, i) => {
      if (i === index) {
        return { ...creation, liked: updatedStatus };
      }
      return creation;
    });

    toast
      .promise(
        ApiService.post(
          `/like_or_dislike_creation/${creationId}/${creationSubId}`,
          { liked: updatedStatus }
        ),
        {
          loading: "Updating like status...",
          success: () => {
            setCreations(updatedCreations);
            return updatedStatus
              ? "Liked successfully!"
              : "Unliked successfully!";
          },
          error: "Failed to update like status. Please try again later.",
        }
      )
      .catch((error) => {
        console.error(error);
      });
  };

  const regenerateCreation = async (creationId, creationSubId) => {
    const index = creations.findIndex(
      (c) => c.creation_id === creationId && c.creation_sub_id === creationSubId
    );
    if (index === -1) {
      toast.error("Creation not found. Please try again later.");
      return;
    }
    await GenerationService.RegenerateCreation(creations[index]);
    fetchArtifactAndCreations();
  };

  const generateNewVariations = async (creationId, creationSubId) => {
    const index = creations.findIndex(
      (c) => c.creation_id === creationId && c.creation_sub_id === creationSubId
    );
    if (index === -1) {
      toast.error("Creation not found. Please try again later.");
      return;
    }
    await GenerationService.GenerateNewVariations(creations[index]);
    fetchArtifactAndCreations();
  };

  useEffect(() => {
    if (artifactId) {
      fetchArtifactAndCreations();
    }
  }, [artifactId]);

  useEffect(() => {
    if (selectedSubArtifact) return;

    const newSelectedSubArtifactIndex = subArtifacts.findIndex(
      (subArtifact) => !subArtifact.hidden
    );
    setSelectedSubArtifactIndex(newSelectedSubArtifactIndex);
  }, [subArtifacts]);

  useEffect(() => {
    if (creations.length > 0) {
      const filtered = creations.filter(
        (creation) =>
          creation.artifact_id ===
          subArtifacts[selectedSubArtifactIndex]?.artifact_id
      );
      setFilteredCreations(filtered);
    }
  }, [selectedSubArtifactIndex, creations, subArtifacts]);

  const generateCreations = ({
    prompt,
    numImagesPerPrompt,
    isRemoveBackground,
    enrichPromptByChatGPTFlag,
    creationId = null,
    image,
    referenceType,
    dimensionsRatio,
    colorPalette,
    settings,
  }) => {
    const timestamp = Date.now();
    // setCanGenerate(false);
    // console.log(selectedSubArtifact.artifact_id);
    // block quest-final generation if quest background is not selected
    if (
      selectedSubArtifact.artifact_id == finalQuestArtifactId &&
      (selectedItems["1"]?.is_place_holder == true || !selectedItems["1"])
    ) {
      toast.error("Please choose a quest background before generating.");
      setCanGenerate(true);
      return;
    }
    const data = {
      prompt: prompt,
      numberOfImages: numImagesPerPrompt,
      isRemoveBackground,
      enrichPromptByChatGPTFlag,
      creationId,
      image,
      referenceType,
      dimensionsRatio,
      colorPalette,
      artifact: selectedSubArtifact,
      artifactId: selectedSubArtifact.artifact_id,
      settings,
    };
    console.log("Data:", data);

    toast.promise(
      (async () => {
        if (selectedSubArtifact.artifact_id == finalQuestArtifactId) {
          data.additionalCreationId = selectedItems["1"].creation_id;
          data.additionalCreationSubId = selectedItems["1"].creation_sub_id;
        }
        // console.log(selectedSubArtifact.artifact_id == finalQuestArtifactId);
        // console.log(data);

        const response = await GenerationService.startGeneration(data);

        if (response.error) {
          throw new Error(response.error);
        }

        await fetchArtifactAndCreations();
        return response;
      })(),
      {
        loading: "Generating creations...",
        success: () => {
          setCanGenerate(true);
          return "Creations generated successfully!";
        },
        error: (err) => {
          console.log(err);
          console.error("Failed to initiate generation:", err);
          setCreations((prevCreations) =>
            prevCreations.filter(
              (creation) =>
                !creation.creation_id.startsWith("temp_" + timestamp)
            )
          );
          setCanGenerate(true);
          return "Failed to initiate generation. Please try again later.";
        },
      }
    );
  };

  const getBackgroundImage = (artifact, index, selectedItems) => {
    const comingSoonImage = `${process.env.PUBLIC_URL}/images/coming_soon.png`;

    const placeholderMapper = {
      [tournamentLeaderboardArtifactId]: `${process.env.PUBLIC_URL}/images/tournament-leaderboard-default-placeholder.png`,
      [tournamentBuyinArtifactId]: `${process.env.PUBLIC_URL}/images/tournament-buyin-default-placeholder.png`,
      [innplayBackgroundArtifactId]: `${process.env.PUBLIC_URL}/images/innplay_background.png`,
      [innplayFinalArtifactId]: `${process.env.PUBLIC_URL}/images/innplay_final.png`,
    };

    if (artifact.hidden) {
      return `url(${comingSoonImage})`;
    }
    if (selectedItems[index]?.result_image_url) {
      return `url(${selectedItems[index].result_image_url})`;
    }
    if (artifact.artifact_id in placeholderMapper) {
      return `url(${placeholderMapper[artifact.artifact_id]})`;
    }
  };

  const fetchArtifactAndCreations = async (
    initialLoad = false,
    startKey = null
  ) => {
    if (initialLoad) {
      setLoading(true);
      setContentVisible(false);
    }
    let artifactResponse = null;
    let creationsResponse;
    console.log("Fetching data...");

    try {
      if (artifactId) {
        const artifactResponse = await ApiService.get(
          `/get_artifact/${artifactId}`
        );
        setArtifact(artifactResponse.data.artifact);
        setSubArtifacts(artifactResponse.data.sub_artifacts);
        const newSubArtifacts = artifactResponse.data.sub_artifacts;

        let fetchedCreations = [];

        for (const subArtifact of newSubArtifacts) {
          console.log(
            "Fetching creations for sub-artifact: ",
            subArtifact.artifact_id
          );
          const creationsResponse = await ApiService.get(
            `/get_creations_based_on_artifact_id?artifact_id=${subArtifact.artifact_id}&limit=${itemsLimit}`
          );
          console.log("Response: ", creationsResponse);
          fetchedCreations = fetchedCreations.concat(creationsResponse.data);
          console.log("Fetched Creations: ", fetchedCreations);
        }
        setCreations(fetchedCreations);
        setLastEvaluatedKey(artifactResponse.next_start_key);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      if (initialLoad) {
        setLoading(false);
      }
    }
  };

  const handleSelectItem = (creation, droppableId) => {
    setSelectedItems((prev) => ({
      ...prev,
      [droppableId]: creation,
    }));
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    let paginatedCreations = [];
    if (filteredCreations.length > 0) {
      paginatedCreations = filteredCreations.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );
    }
    setPaginatedCreations(paginatedCreations);
  }, [filteredCreations, currentPage]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkStatusOfPendingCreations();
    }, 10000);

    return () => clearInterval(interval);
  }, [creations]);

  const checkStatusOfPendingCreations = async () => {
    const pendingCreations = creations?.filter(
      (creation) => creation.status === "pending"
    );
    if (!pendingCreations || pendingCreations.length === 0) {
      return;
    }

    const payload = pendingCreations.map((creation) => ({
      creation_id: creation.creation_id,
      creation_sub_id: creation.creation_sub_id,
    }));

    try {
      const response = await ApiService.post("/check_status", payload);
      console.log(response);
      const updatedCreations = response.results;

      setCreations((prevCreations) =>
        prevCreations.map((c) => {
          const updatedCreation = updatedCreations.find(
            (uc) =>
              uc.creation_id === c.creation_id &&
              uc.creation_sub_id === c.creation_sub_id
          );
          return updatedCreation && !updatedCreation.error
            ? { ...c, ...updatedCreation.data }
            : c;
        })
      );
    } catch (error) {
      console.error("Error checking status:", error);
    }
  };

  useEffect(() => {
    console.log("HELOOOOOOOOO")
    console.log("number of tiles options:", artifact?.number_of_tiles);
  }, [artifact]);

  return (
    <div
      className={`${styles.shop} ${generationErrorMessage ? styles.fadeIn : ""}`}>
      <div className={styles.background}>
        <img src={organizationData?.app_bg} alt="Background" />
      </div>
      {selectedSubArtifact && (
        <>
          <Profile
            artifact={selectedSubArtifact}
            generateCreations={generateCreations}
            error={generationErrorMessage}
            canGenerate={canGenerate}
            baseCreation={baseCreation}
          />

          <Card className={styles.card}>
            <center>
              <div className={styles.toggleContainer}>
                {subArtifacts.map((subArtifact, index) => (
                  <div
                    key={index}
                    className={`${styles.toggleButton} ${
                      selectedSubArtifactIndex === index ? styles.active : ""
                    } ${subArtifact.hidden ? styles.disabled : ""}`}
                    onClick={() =>
                      !subArtifact.hidden && toggleSubArtifact(index)
                    }
                    style={{
                      pointerEvents: subArtifact.hidden ? "none" : "auto",
                    }}
                  >
                    {subArtifact.display_name.includes("-")
                      ? subArtifact.display_name
                          .split("-")[1]
                          .trim()
                          .split(" ")[0]
                          .charAt(0)
                          .toUpperCase() +
                        subArtifact.display_name
                          .split("-")[1]
                          .trim()
                          .split(" ")[0]
                          .slice(1)
                      : subArtifact.display_name || `Stage ${index + 1}`}{" "}
                  </div>
                ))}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className={styles.wrap}>
                  <div
                    className={cn(styles.gallery, {
                      [styles.triple]: isNotSimple,
                    })}
                  >
                    {paginatedCreations.map((creation, index) => (
                      <div key={index} className={styles.productWrapper}>
                        <CreationCard
                          item={creation}
                          showPrompt={false}
                          fixedSize={200}
                          onLikeDislike={() =>
                            toggleLikeStatus(
                              creation.creation_id,
                              creation.creation_sub_id
                            )
                          }
                          onRegenerateClick={
                            artifactId
                              ? () =>
                                  regenerateCreation(
                                    creation.creation_id,
                                    creation.creation_sub_id
                                  )
                              : null
                          }
                          onGenerateVariationsClick={
                            artifactId && !artifact?.use_flux
                              ? () =>
                                  generateNewVariations(
                                    creation.creation_id,
                                    creation.creation_sub_id
                                  )
                              : null
                          }
                          onShowPreview={() =>
                            openModal(
                              filteredCreations,
                              index,
                              selectedSubArtifact,
                              fetchArtifactAndCreations
                            )
                          }
                        />
                        {creation.status === "completed" && (
                          <button
                            className={styles.chooseButton}
                            onClick={() =>
                              handleSelectItem(
                                creation,
                                selectedSubArtifactIndex
                              )
                            }
                          >
                            Choose
                          </button>
                        )}
                      </div>
                    ))}
                  </div>

                  <div className={styles.bottomPanel}>
                    <div className={styles.pagination}>
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Newer
                      </button>
                      <span>{currentPage}</span>
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={
                          currentPage * itemsPerPage >= filteredCreations.length
                        }
                      >
                        Older
                      </button>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: 15, marginRight: -20, height: 600 }}>
                  {!DicesArtifactsIds.includes(artifact.artifact_id) &&
                    subArtifacts.map((subArtifact, index) => (
                      <center key={index}>
                        <div
                          key={index}
                          className={styles.pickedScreenContainer}
                        >
                          <div
                            className={cn(styles.pickedScreen)}
                            style={{
                              width:
                                subArtifact.artifact_id ===
                                  finalQuestArtifactId ||
                                subArtifact.artifact_id ===
                                  innplayBackgroundArtifactId
                                  ? 251.5
                                  : "",
                              height:
                                subArtifact.artifact_id === finalQuestArtifactId
                                  ? 338.75
                                  : subArtifact.artifact_id ===
                                    innplayBackgroundArtifactId
                                  ? 436.75
                                  : "",
                              backgroundImage: getBackgroundImage(
                                subArtifact,
                                index,
                                selectedItems
                              ),
                            }}
                          >
                            {!subArtifact.hidden &&
                              (subArtifact.artifact_id ===
                              innplayBackgroundArtifactId ? (
                                <img
                                  src={`${process.env.PUBLIC_URL}/images/items_only.png`}
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    zIndex: 2,
                                    pointerEvents: "none",
                                  }}
                                />
                              ) : artifact.artifact_id ===
                                "eaeee3c1-7897-4152-aef4-d9450719b198" ? (
                                index === 1 ? (
                                  <img
                                    src={`${process.env.PUBLIC_URL}/images/tournamentFull.png`}
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      zIndex: 2,
                                      pointerEvents: "none",
                                    }}
                                  />
                                ) : (
                                  <>
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/tournamentLeaderboard.png`}
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        zIndex: 4,
                                        pointerEvents: "none",
                                      }}
                                    />
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: -101,
                                        left: 0,
                                        width: "100%",
                                        height: "calc(100% - 10px)",
                                        backgroundImage: selectedItems[index]
                                          ?.result_image_url
                                          ? `url(${selectedItems[index].result_image_url})`
                                          : null,
                                        backgroundSize: "contain",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        zIndex: 1,
                                      }}
                                    />
                                  </>
                                )
                              ) : null)}
                          </div>
                          <div
                            className={cn(styles.backgroundIndicator, {
                              [styles.activeIndicatorTopTournament]:
                                artifact.artifact_id ===
                                  "eaeee3c1-7897-4152-aef4-d9450719b198" &&
                                index === 0,
                              [styles.activeIndicator]:
                                selectedSubArtifactIndex === index,
                            })}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: "136.6%",
                              height: "15%",
                              transform: "translateX(-100%)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {selectedSubArtifactIndex === index && (
                              <img
                                src={`${process.env.PUBLIC_URL}/images/svg/leftyArrow.png`}
                                alt="Active Indicator"
                                style={{ width: "30px", height: "30px" }}
                              />
                            )}
                          </div>
                        </div>
                      </center>
                    ))}

                  <center>
                    {!DicesArtifactsIds.includes(artifact.artifact_id) &&
                      (selectedItems[0]?.result_image_url ||
                        selectedItems[1]?.result_image_url) && (
                        <div
                          className={cn("button button-small", styles.button)}
                          style={{ marginTop: 20 }}
                          onClick={onDownload}
                        >
                          <Icon name="download" size="24" />
                          <span
                            className={styles.download}
                            style={{
                              pointerEvents: "auto",
                              width: "100%",
                              cursor: "pointer",
                            }}
                          >
                            Download Now
                          </span>
                        </div>
                      )}
                  </center>
                </div>
              </div>
              <div
                style={{
                  marginTop: 10,
                }}
              >
                {DicesArtifactsIds.includes(artifact.artifact_id) &&
                  (console.log("Dice Artifact", artifact),
                  (
                    // console.log("Dice Artifact", artifact),
                    <div
                      className={styles.diceContainer}
                      style={{
                        position: "relative",
                        height: 300,
                        zIndex: 1,
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        className={styles.toggleContainer}
                        style={{
                          display: "inline-block",
                          position: "relative",
                          overflow: "visible",
                        }}
                      >
                        <div
                          className={styles.toggleContainer}
                          style={{
                            position: "absolute",
                            display: "flex",
                            flexDirection: "column",
                            margin: 0,
                            padding: 0,
                            left: "-105px",
                            top: -3,
                          }}
                        >
                          {Object.keys(artifact.number_of_tiles).map(
                            (tileKey) => {
                              if (artifact.number_of_tiles[tileKey]) {
                                return (
                                  <div
                                    key={tileKey}
                                    className={`${styles.toggleButton} ${
                                      numberOfTilesToDisplay === tileKey
                                        ? styles.active
                                        : ""
                                    }`}
                                    onClick={() => {
                                      setActiveTileKey(tileKey);
                                      setNumberOfTilesToDisplay(tileKey);
                                      console.log("tiles to display:",tileKey);
                                    }}
                                    style={{
                                      pointerEvents: "auto",
                                      fontSize: 16,
                                      padding: "7px 15px",
                                      margin: "2px",
                                    }}
                                  >
                                    {`${tileKey} Tiles`}
                                  </div>
                                );
                              }
                              return null;
                            }
                          )}
                        </div>
                        <div
                          style={{
                            backgroundImage: selectedItems[0]?.result_image_url
                              ? `url(${selectedItems[0].result_image_url})`
                              : organization==="SOLITAIRE-4f320b" ? `url(${process.env.PUBLIC_URL}/images/solitaire_background_dice_default.png)` :`url(${process.env.PUBLIC_URL}/images/bingo_background_dice_default.png)`,
                            width: 438,
                            height: 270,

                            backgroundSize: "cover",
                            top: 0,
                            left: 0,
                            zIndex: 1,
                          }}
                        />
                      </div>
                      <div
                        // TILES_BACKGROUND
                        style={{
                          backgroundImage: (() => {
                            switch (numberOfTilesToDisplay) {
                              case "8":
                                  return selectedItems[1]?.result_image_url
                                      ? `url(${selectedItems[1].result_image_url[0]})`
                                      : `url(${process.env.PUBLIC_URL}/images/dice_road_default_8.png)`;
                              case "12":
                                  return selectedItems[1]?.result_image_url
                                      ? `url(${selectedItems[1].result_image_url[1]})`
                                      : null;
                              case "15":
                                  return selectedItems[1]?.result_image_url
                                      ? `url(${selectedItems[1].result_image_url[2]})`
                                      : null;
                              case "20":
                                  return selectedItems[1]?.result_image_url
                                      ? `url(${selectedItems[1].result_image_url[3]})`
                                      : `url(${process.env.PUBLIC_URL}/images/dice_road_default_8.png)`;
                              case "30":
                                  return selectedItems[1]?.result_image_url
                                      ? `url(${selectedItems[1].result_image_url[4]})`
                                      : null;
                              case "40":
                                  return selectedItems[1]?.result_image_url
                                      ? `url(${selectedItems[1].result_image_url[5]})`
                                      : null;
                              case "50":
                                  return selectedItems[1]?.result_image_url
                                      ? `url(${selectedItems[1].result_image_url[6]})`
                                      : `url(${process.env.PUBLIC_URL}/images/dice_road_default_50.png)`;
                              default:
                                  return null;
                          }
                          })(),
                          width: 300,
                          height: 225,
                          position: "relative",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          bottom: 242,
                          left: 0,
                          zIndex: 2,
                        }}
                      ></div>
                      <div
                        // TILES
                        style={{
                          // backgroundImage: selectedItems[1]?.result_image_url
                          //   ? `url(${selectedItems[1].result_image_url})`
                          //   : `url(${process.env.PUBLIC_URL}/images/${
                          //       numberOfTilesToDisplay === "8"
                          //         ? null
                          //         : numberOfTilesToDisplay === "20"
                          //         ? null
                          //         : numberOfTilesToDisplay === "50"
                          //         ? null
                          //         : null
                          //     })`,
                          backgroundImage: null,
                          width: 300,
                          height: 150,
                          position: "relative",
                          backgroundSize: "cover",
                          bottom: 369,
                          left: 0,
                          zIndex: 3,
                        }}
                      ></div>
                    </div>
                  ))}
              </div>

              {DicesArtifactsIds.includes(artifact.artifact_id) &&
                // selectedItems[0]?.result_image_url &&
                // selectedItems[1]?.result_image_url && (
                //   <div
                //     className={cn("button button-small", styles.button)}
                //     onClick={onDownload}
                //   >
                //     <Icon name="download" size="24" />
                //     <span
                //       className={styles.download}
                //       style={{
                //         pointerEvents: "auto",
                //         width: "100%",
                //         cursor: "pointer",
                //       }}
                //     >
                //       Download Now
                //     </span>
                //   </div>
                (selectedItems[0]?.result_image_url || selectedItems[1]?.result_image_url) && (
                  <>
                  <div>
                    {selectedItems[0]?.result_image_url && !selectedItems[1]?.result_image_url && (
                      <div
                        className={cn("button button-small", styles.button)}
                        onClick={onDownload} // Pass 0 or any specific identifier for selectedItems[0]
                      >
                        <Icon name="download" size="24" />
                        <span
                          className={styles.download}
                          style={{
                            pointerEvents: "auto",
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          Download Background
                        </span>
                      </div>
                    )}
                    {selectedItems[1]?.result_image_url && !selectedItems[0]?.result_image_url && (
                      <div
                        className={cn("button button-small", styles.button)}
                        onClick={onDownload} // Pass 1 or any specific identifier for selectedItems[1]
                      >
                        <Icon name="download" size="24" />
                        <span
                          className={styles.download}
                          style={{
                            pointerEvents: "auto",
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          Download Road
                        </span>
                      </div>
                    )}
                    {selectedItems[0]?.result_image_url &&
                      selectedItems[1]?.result_image_url && (
                        <div
                          className={cn("button button-small", styles.button)}
                          onClick={onDownload} // Function to download both items
                        >
                          <Icon name="download" size="24" />
                          <span
                            className={styles.download}
                            style={{
                              pointerEvents: "auto",
                              width: "100%",
                              cursor: "pointer",
                            }}
                          >
                            Download All
                          </span>
                        </div>
                      )}
                  </div>
                  {/* <div
                    className={cn("button button-small", styles.button)}
                    style={{ marginTop: 10, backgroundColor:"red"}}
                    onClick={() => {
                      handleSelectItem(
                        null,
                        0
                      );
                      handleSelectItem(
                        null,
                        1
                      )
                    }}>
                    Clear picks
                  </div> */}
                  </>
                )}
                  
            </center>
          </Card>
        </>
      )}
    </div>
  );
};

export default GenerateOfferScreen3;
