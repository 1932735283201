import axios from 'axios';
import { useEffect } from 'react';

export const styleFilter = [
    {
    label: "Assets",
    value: "asset",
    children: [
        {
        label: "Backgrounds",
        value: "Background",
        },
        {
        label: "Characters",
        value: "character",
        children: [
            { label: "Donna the Dear", value: "Donna the Dear" },
            { label: "Danny the Rabbit", value: "Danny the Rabbit" },
        ],
        },
        {
        label: "Items",
        value: "Item",
        }
    ],
    },
    {
    label: "Offers",
    value: "offer",
    children: [
        {
        label: "Personal Offer 6",
        value: "Personal Offer 6",
        },
        {
        label: "Triple Rolling Offer",
        value: "Triple Rolling Offer",
        },
    ],
    },
    {
    label: "Features",
    value: "feature",
    children: [
        {
        label: "Quest",
        value: "quest",
        },
    ],
    },
    {
    label: "Albums",
    value: "album",
    },
];

export const dateFilter = [
    {
        label: "All Time",
        value: "all Time",
    },
    {
        label: "Last 7 days",
        value: "last 7 days",
    },
    {
        label: "Last 30 days",
        value: "last 30 days",
    },
    {
        label: "Last 90 days",
        value: "last 90 days",
    },
]

export const artistFilter = [
    {
        label: "roisafran@gmail.com",
        value: "roisafran@gmail.com",
    },
    {
        label: "dfgsdfgsdg@gmail.com",
        value: "dfgsdfgsdg@gmail.com",
    },
]


// Function to fetch the nested tree data from the endpoint
// export const fetchNestedTreeData = async (organizationId) => {
//     try {
//         const response = await axios.get('/create_style_filter_by_organization', {
//             params: { organization_id: organizationId },
//         });

//         return response.data;
//     } catch (error) {
//         console.error('Error fetching nested tree data:', error);
//         throw error;
//     }
// };

// useEffect(() => {
//     const organizationId = "INNPLAY-0c0dc5";
//     fetchNestedTreeData(organizationId)
//         .then((nestedTreeData) => {
//             console.log('Nested Tree Data:', nestedTreeData);
//         })
//         .catch((error) => {
//             console.error('Failed to fetch nested tree data:', error);
//         }
//     );
// }, []);
