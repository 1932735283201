import React, { useState, useEffect, use } from "react";
import Card from "../../components/Card";
import styles from "./MultiAssetsComposition.module.sass";
import { useLocation } from "react-router-dom";
import cn from "classnames";
import { toast } from "react-hot-toast";
import ZipCreator from "../../services/ZipCreatorService";
import GenerationService from "../../services/GenerationService";
import { useAuth } from "../../contexts/AuthContext";
import CreationCard from "../../components/Product";
import { useModalPreview } from "../../contexts/ModalPreviewContext";
import ApiService from "../../services/ApiService";
import PromptBox from "../../components/PromptBox";
import { customFlattenObject, parseNumericValues } from "../../utils";
import { useParams } from "react-router-dom";
import { useLoadingBar } from "../../contexts/LoadingBarContext";

const MultiAssetsComposition = () => {
  const { openModal } = useModalPreview();
  const { progress, setProgress } = useLoadingBar();
  const [creations, setCreations] = useState([]);
  const [generationErrorMessage, setGenerationErrorMessage] = useState("");
  const [selectedItems, setSelectedItems] = useState({});
  const [artifact, setArtifact] = useState(null);
  const [assets, setAssets] = useState([]);
  const location = useLocation();
  const { organizationData } = useAuth();
  const [selectedAsset, setSelectedAsset] = useState();
  const [selectedAssetIndex, setSelectedAssetIndex] = useState(0);
  const [creationsByAssetId, setCreationsByAssetId] = useState({});
  const organization = organizationData.organization
  const [currentPrompt, setCurrentPrompt] = useState("");
  const [showGenerateMore, setShowGenerateMore] = useState(false);
  const [renderingSettings, setRenderingSettings] = useState(null);
  const { artifactId } = useParams();
  const [enrichPromptByChatGPTFlag, setEnrichPromptByChatGPTFlag] = useState(true);
  const [beginningFlag, setBeginningFlag] = useState(true);
  const [zoomLevel, setZoomLevel] = useState(renderingSettings?.template?.default_zoom || 1);

  // useEffect(() => {
  //   // Check if the page has been refreshed before
  //   const refreshed = sessionStorage.getItem("refreshed");
  //   console.log("refreshed", refreshed);
  //   if (!refreshed) {
  //     // Set the flag to indicate the page has been refreshed
  //     sessionStorage.setItem("refreshed", "true");
  //     // Reload the page
  //     window.location.reload();
  //   } else {
  //     // Clear the flag after the first load to allow normal navigation
  //     sessionStorage.removeItem("refreshed");
  //   }
  // }, []);
  
  //reset the states when the page is loaded
  
  useEffect(() => {
    setBeginningFlag(true)
    setSelectedAssetIndex(0);
    setSelectedItems({});
    setCreationsByAssetId({});
  }, []);

  useEffect(() => {
    if (renderingSettings && renderingSettings.template['default_zoom']) {
      setZoomLevel(renderingSettings.template['default_zoom']);
    }
  }, [renderingSettings]);

  useEffect(() => {
    async function fetchArtifact() {
      setProgress(0);
      if (artifactId) {
        try {
          const ARTIFACT_ENDPOINT = `/get_artifact/${artifactId}`;
          setProgress(30);
          const artifactResponse = await ApiService.get(ARTIFACT_ENDPOINT);
          setProgress(60);
          const { preview_JSON_url } = artifactResponse.data.artifact;
          const templateResponse = await fetch(preview_JSON_url);
          setProgress(80);
          const template = await templateResponse.json();
          setRenderingSettings(template);
          setArtifact(artifactResponse.data.artifact);
          const processedAssets = artifactResponse.data.assets.map(asset => {
            if (asset.ingredient && asset.ingredient.changes) {
              asset.ingredient.changes = parseNumericValues(asset.ingredient.changes);
            }
            return asset;
          });
          // setAssets(artifactResponse.data.assets);
          setAssets(processedAssets);
          
          setProgress(100);
        } catch (error) {
          console.error("Error fetching artifact:", error);
          setProgress(0);
        }
      }
    }

    fetchArtifact();
  }, [artifactId]);

  useEffect(() => {
    if (selectedAsset) {
      const creations = creationsByAssetId[selectedAsset.recipe_unique_id];
      if (creations && creations.some(creation => creation.status === 'completed')) {
        setShowGenerateMore(true);
      }
    }
  }, [selectedAsset]);

  useEffect(() => {
    if (artifact) {
      if (assets && assets.length > 0) {
        setSelectedAsset(assets[0]);
      }
    }
  }, [artifact]);

  useEffect(() => {
    setSelectedAsset(assets[selectedAssetIndex]);
  }, [selectedAssetIndex]);

  useEffect(() => {
    // Poll every 10 seconds
    const interval = setInterval(() => {
      checkPendingCreationsInCreationsByAssetId();
    }, 10_000);
  
    return () => clearInterval(interval);
  }, [creationsByAssetId]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkStatusOfPendingCreations();
    }, 10000);

    return () => clearInterval(interval);
  }, [creations]);

  useEffect(() => {
    if (
      creationsByAssetId &&
      Object.keys(selectedItems).length < assets.length && beginningFlag// Check if no items are already selected
    ) {
      const initialSelectedItems = {};
      Object.keys(creationsByAssetId).forEach((assetId) => {
        const creations = creationsByAssetId[assetId];
        if (creations && creations.length > 0) {
          // Find the first creation with a valid `result_image_url`
          const firstValidCreation = creations.find(
            (creation) =>
              Array.isArray(creation.result_image_url) &&
              creation.result_image_url.length > 0 &&
              creation.result_image_url[0] // Ensure the URL exists and is valid
          );
          if (firstValidCreation) {
            initialSelectedItems[assetId] = {
              ...firstValidCreation,
              renderingSettings: renderingSettings?.template?.assets?.find(
                (asset) => asset.recipe_unique_id === assetId
              ),
            };
          }
        }
      });
      setSelectedItems(initialSelectedItems);
      if (initialSelectedItems.length > assets.length) {
        setBeginningFlag(false)
      }
    }
  }, [creationsByAssetId]);

  useEffect(() => {
    console.log("selectedItems", selectedItems);
  }, [selectedItems]);

  const checkPendingCreationsInCreationsByAssetId = async () => {
    // 1) Build the list of pending creations from across all assets
    const pendingList = [];
    for (const assetId in creationsByAssetId) {
      const assetCreations = creationsByAssetId[assetId] || [];
      assetCreations.forEach((creation) => {
        if (creation.status === "pending") {
          pendingList.push({
            assetId,
            creation_id: creation.creation_id,
            creation_sub_id: creation.creation_sub_id,
          });
        }
      });
    }
  
    if (!pendingList.length) {
      return; // No pending items
    }
  
    try {
      // 2) Call /check_status with an array of {creation_id, creation_sub_id}
      const payload = pendingList.map((item) => ({
        creation_id: item.creation_id,
        creation_sub_id: item.creation_sub_id,
      }));
      const response = await ApiService.post("/check_status", payload);
      // e.g. response.data.results = [ { creation_id, creation_sub_id, data: {...}, error: ... }, ...]
  
      const updatedCreations = response.results || [];
  
      // 3) Merge them back into creationsByAssetId
      const newMap = { ...creationsByAssetId };
  
      updatedCreations.forEach((upd) => {
        if (upd.error) {
          // e.g. if no creation found or some other error
          console.error("Error updating creation:", upd.error);
          return;
        }
        // Find which asset it belongs to
        const foundPendingItem = pendingList.find(
          (p) =>
            p.creation_id === upd.creation_id &&
            p.creation_sub_id === upd.creation_sub_id
        );
        if (!foundPendingItem) return;
        const assetId = foundPendingItem.assetId;
  
        // Update the relevant creation
        newMap[assetId] = newMap[assetId].map((c) =>
          c.creation_id === upd.creation_id &&
          c.creation_sub_id === upd.creation_sub_id
            ? { ...c, ...upd.data }
            : c
        );
      });
  
      setCreationsByAssetId(newMap);
    } catch (error) {
      console.error("Error checking status for multi-assets:", error);
    }
  };
  
  const toggleAsset = (index) => {
    setSelectedAssetIndex(index);
    setSelectedAsset(assets[index]);
  };

  const onDownload = async () => {
    if (!selectedItems || Object.keys(selectedItems).length === 0) {
      toast.error("No selected items to download.");
      return;
    }
  
    const files = Object.values(selectedItems).flatMap((item) => {
      if (item && item.result_image_url) {
        return item.result_image_url.map((url, index) => ({
          name: `${item.creation_id}_${item.creation_sub_id}_${index}.png`,
          url,
        }));
      }
      return [];
    });
  
    if (files.length === 0) {
      toast.error("No files available for download.");
      return;
    }
    const zipName = `${artifact?.display_name || "download"}_creations.zip`;
  
    try {
      await toast.promise(
        ZipCreator.createZipFile(files, zipName),
        {
          loading: "Preparing files for download...",
          success: "Download completed successfully!",
          error: "Failed to download some files. Please try again.",
        }
      );
    } catch (error) {
      console.error("Failed to create zip file:", error);
      toast.error("An error occurred while preparing the download.");
      return;
    }
  
    // Update backend for tracking downloads
    try {
      await Promise.all(
        Object.values(selectedItems).map(async (item) => {
          if (item && item.creation_id && item.creation_sub_id) {
            try {
              await ApiService.post(
                `/download_creation/${item.creation_id}/${item.creation_sub_id}`
              );
            } catch (error) {
              console.error(
                `Failed to update download for item ${item.creation_id}:`,
                error
              );
            }
          }
        })
      );
    } catch (error) {
      console.error("Error updating backend after downloads:", error);
    }
  };

  const zoomButtons = () => {
    if(renderingSettings || renderingSettings?.template) {
      return (
        <>
        <button
          onClick={handleZoomIn}
          className={[styles.zoomButtons, styles.zoomInButton].join(" ")}
        >
          <img
              src="https://prod-genielabs-ui-bucket.s3.us-east-2.amazonaws.com/system-design-ui-assets/icons/zoom_in_outline_default.svg"
              alt="Zoom In"
              style={{ width: 19, height: 19}}
            />
        </button>
        <button
          onClick={handleZoomOut}
          // className={styles.zoomButtons}
          className={[styles.zoomButtons, styles.zoomOutButton].join(" ")}
        >
          <img
            src="https://prod-genielabs-ui-bucket.s3.us-east-2.amazonaws.com/system-design-ui-assets/icons/zoom_out_outline_default.svg"
            alt="Zoom Out"
            style={{ width: 19.5, height: 19.5}}
          />
        </button>
        </>
      )
    }
  }
  
  const toggleLikeStatus = async (creation, creationId, creationSubId) => {
    const assetCreations = creationsByAssetId[selectedAsset.recipe_unique_id];
    if (!assetCreations) {
      toast.error("No creations found for this asset. Please try again later.");
      return;
    }
    const index = assetCreations.findIndex(
      (c) => c.creation_id === creationId && c.creation_sub_id === creationSubId
    );
    if (index === -1) {
      toast.error("Creation not found. Please try again later.");
      return;
    }
    const updatedStatus = !assetCreations[index].liked;
    const updatedCreationsForAsset = assetCreations.map((creation, i) => {
      if (i === index) {
        return { ...creation, liked: updatedStatus };
      }
      return creation;
    });
    const newCreationsMap = {
      ...creationsByAssetId,
      [selectedAsset.recipe_unique_id]: updatedCreationsForAsset,
    };
    toast
      .promise(
        ApiService.post(
          `/like_or_dislike_creation/${creationId}/${creationSubId}`,
          { liked: updatedStatus }
        ),
        {
          loading: "Updating like status...",
          success: () => {
            setCreationsByAssetId(newCreationsMap);
            return updatedStatus
              ? "Liked successfully!"
              : "Unliked successfully!";
          },
          error: "Failed to update like status. Please try again later.",
        }
      )
      .catch((error) => {
        console.error(error);
      });
  };

  const handleGenerateAllAssets = async ({
    prompt,
    numImagesPerPrompt = 3,
    isRemoveBackground,
    enrichPromptByChatGPTFlag: enrichPromptByChatGPTFlag,
    image,
    referenceType,
    colorPalette,
    settings,
  }) => {
    // 1) Check if you actually have assets to generate
    if (!assets || !assets.length) {
      toast.error("No assets to generate. Please try again later.");
      return;
    }
    
    // Clear any previous errors
    setGenerationErrorMessage("");
    setBeginningFlag(true)
    setSelectedItems({});
    try {
      await toast.promise(
      (async () => {
      // 2) Build an array of Promises, one per asset
      const requests = assets.map((asset) => {
        // Optionally skip hidden assets
        if (asset.hidden) {
          return Promise.resolve(null);
        }
        // Build the data object for each asset
        const data = {
          prompt,
          numberOfImages: numImagesPerPrompt,
          isRemoveBackground,
          enrichPromptByChatGPTFlag: enrichPromptByChatGPTFlag,
          image,
          referenceType,
          colorPalette,
          settings,
          artifactId: asset.ingredient.artifact_id,
          changes: customFlattenObject(asset.ingredient.changes)
        };
        // a) Start the generation for this asset
        return GenerationService.startGeneration(data)
          .then((startGenRes) => {
            // If the backend returned an error
            if (startGenRes?.error) {
              return { error: startGenRes.error, assetId: asset.recipe_unique_id ,assetArtifactId: asset.ingredient.artifact_id };
            }
            const { creation_id } = startGenRes;
            if (!creation_id) {
              return {
                error: "No creation_id returned from start_generation",
                assetId: asset.recipe_unique_id,
                assetArtifactId: asset.ingredient.artifact_id
              };
            }
  
            // b) Once we get creation_id, fetch from /get_creations_by_creation_id
            return ApiService.get(`/get_creations_by_creation_id?creation_id=${creation_id}`)
              .then((fetchRes) => {
                // The route returns an array of creation objects
                const creationItems = fetchRes || [];
                return {
                  assetId: asset.recipe_unique_id,
                  assetArtifactId: asset.ingredient.artifact_id,
                  creations: creationItems,
                };
              })
              .catch((fetchErr) => {
                console.error("Error fetching creations by creation_id:", fetchErr);
                return { error: fetchErr, assetId: asset.recipe_unique_id ,assetArtifactId: asset.ingredient.artifact_id };
              });
          })
          .catch((err) => {
            // Catch any error from startGeneration itself
            console.error(`Generation failed for asset [${asset.recipe_unique_id}]`, err);
            return { error: err, assetId: asset.recipe_unique_id ,assetArtifactId: asset.ingredient.artifact_id };
          });
      });
  
      // 3) Execute all requests in parallel with Promise.all
      const results = await Promise.all(requests);
  
      // 4) Merge each asset’s newly fetched creations into your state
      const newCreationsMap = { ...creationsByAssetId };
      results.forEach((res) => {
        if (!res) return; // e.g., if asset was hidden
        const { assetId, creations, error } = res;
        if (error) {
          console.error("Error generating/fetching for asset:", assetId, error);
          toast.error(`Failed to generate an asset.`);
          return;
        }
  
        // If success, store the returned creations in the map
        newCreationsMap[assetId] = creations;
      });
      
      // 5) Update state so Section #2 can display them
      setCreationsByAssetId(newCreationsMap);
      })(),
      {
        loading: "Generating all assets...",
        success: "All Creations generated successfully!",
        error: "Failed to initiate generation for all assets.",
      }
    );
    } catch (err) {
      // If anything goes wrong at the top level
      console.error("Failed to generate all assets:", err);
      setGenerationErrorMessage("Failed to initiate generation for all assets.");
    }
  };  
  
  const handleGenerateSingleAsset = async ({
    prompt,
    numImagesPerPrompt = 3,
    isRemoveBackground,
    enrichPromptByChatGPTFlag,
    image,
    referenceType,
    colorPalette,
    settings,
    
  }) => {
    // 1) Make sure we have a selectedAsset
    if (!selectedAsset) {
      toast.error("Please select an asset first.");
      return;
    }
  
    setGenerationErrorMessage("");
    setBeginningFlag(false)
    
    try {
      // Build the request data for your single asset
      const data = {
        prompt,
        numberOfImages: numImagesPerPrompt,
        isRemoveBackground,
        enrichPromptByChatGPTFlag: enrichPromptByChatGPTFlag,
        image,
        referenceType,
        colorPalette,
        settings,
        artifactId: selectedAsset.ingredient.artifact_id,
        // changes: selectedAsset.ingredient.changes,
        changes: customFlattenObject(selectedAsset.ingredient.changes)
      };
  
      // 2) Call startGeneration for the single selected asset
      await toast.promise(
        (async () => {
          const startGenRes = await GenerationService.startGeneration(data);
      
          if (startGenRes?.error) {
            // If the backend returned an error
            console.error(
              "Generation failed for asset:",
              selectedAsset.recipe_unique_id,
              startGenRes.error
            );
            toast.error(
              `Failed to generate for an asset: ${startGenRes.error}`
            );
            return;
          }
      
          // // 3) Check if we got a creation_id
          const { creation_id } = startGenRes;
          if (!creation_id) {
            console.error(
              "No creation_id returned for asset:",
              selectedAsset.recipe_unique_id
            );
            toast.error(`No creation_id returned from start_generation.`);
            return;
          }
      
          // 4) Now fetch the newly inserted creations for that creation_id
          const fetchRes = await ApiService.get(
            `/get_creations_by_creation_id?creation_id=${creation_id}`
          );
          console.log(
            "Fetched creations for asset:",
            selectedAsset.recipe_unique_id,
            "fetchRes:",
            fetchRes
          );
          // If fetchRes is the full response, the actual array may be in `fetchRes.data`
          // Adjust if your endpoint shape differs
          const newlyFetchedCreations = fetchRes || [];
      
          // 5) Merge the new creations with the existing ones for this asset
          const newCreationsMap = { ...creationsByAssetId };
          // If there's no array yet for this asset, default to an empty array
          const existingCreations = newCreationsMap[selectedAsset.recipe_unique_id] || [];
          // Append the new creations
          const updatedCreations = [...existingCreations, ...newlyFetchedCreations];
      
          // 6) Update your state to reflect the new total
          newCreationsMap[selectedAsset.recipe_unique_id] = updatedCreations;
          console.log("Updated creations for asset:", selectedAsset.recipe_unique_id, updatedCreations);
      
          setCreationsByAssetId(newCreationsMap);
        })(),
        {
          loading: "Generating more creation...",
          success: "Creation(s) generated successfully!",
          error: "Failed to initiate generation for this asset.",
        }
      );
    } catch (err) {
      console.error("Failed to generate single asset:", err);
      setGenerationErrorMessage("Failed to initiate generation for this asset.");
    }
  };

  const checkStatusOfPendingCreations = async () => {
    const pendingCreations = creations?.filter(
      (creation) => creation.status === "pending"
    );
    if (!pendingCreations || pendingCreations.length === 0) {
      return;
    }

    const payload = pendingCreations.map((creation) => ({
      creation_id: creation.creation_id,
      creation_sub_id: creation.creation_sub_id,
    }));

    try {
      const response = await ApiService.post("/check_status", payload);
      console.log(response);
      const updatedCreations = response.results;

      setCreations((prevCreations) =>
        prevCreations.map((c) => {
          const updatedCreation = updatedCreations.find(
            (uc) =>
              uc.creation_id === c.creation_id &&
              uc.creation_sub_id === c.creation_sub_id
          );
          return updatedCreation && !updatedCreation.error
            ? { ...c, ...updatedCreation.data }
            : c;
        })
      );
    } catch (error) {
      console.error("Error checking status:", error);
    }
  };

  const handleSelectItem = (creation, recipeUniqueId) => {
    console.log("handleSelectItem", creation, "\n","assetId", recipeUniqueId);
    const assetSettings = renderingSettings["template"]["assets"].find((asset) => asset.recipe_unique_id === recipeUniqueId);
    if (!assetSettings) return;
  
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [recipeUniqueId]: creation, // Map the creation to the recipe_unique_id
    }));
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 2));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 0.1));
  }

  const renderTemplate = () => {
    if (!renderingSettings || !renderingSettings.template) {
      // Show a loading state or return null while waiting for renderingSettings to load
      return <p>Loading template...</p>;
    }
  
    const { width: templateWidth, height: templateHeight, assets } = renderingSettings.template;
  
    return (
      <div
        style={{
          position: "relative",
          width: `${templateWidth}px`,
          height: `${templateHeight}px`,
          backgroundColor: "white",
          margin: "auto",
          overflow: "hidden",
          zoom: zoomLevel,
          overflowX: "auto"
        }}
      >
        {renderUIElements()}
        {/* {renderSelectedItems()} */}
            {/* Render Assets */}
        {assets.map((asset, index) => {
          const creation = selectedItems[asset.recipe_unique_id];
          if (!creation || !Array.isArray(creation.result_image_url)) return null;

          return (
            <div
              key={index}
              style={{
                position: "absolute",
                left: `${asset.x}px`,
                top: `${asset.y}px`,
                width: `${asset.width}px`,
                height: `${asset.height}px`,
                zIndex: asset.zIndex || 1,
                overflow: "hidden",
              }}
            >
              <img
                src={creation.result_image_url[0]}
                alt={`Asset ${asset.recipe_unique_id}`}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };
  
  const renderUIElements = () => {
    if (!renderingSettings || !renderingSettings.template) {
      return null;
    }
  
    const uiElements = renderingSettings.template.uiElements; // Accessing the correct path
    if (!uiElements || !Array.isArray(uiElements)) {
      console.log("No UI Elements found");
      return null;
    }
  
    return uiElements.map((uiElement, index) => (
      <div
        key={index} // Ensure a unique key for each element
        style={{
          position: "absolute",
          left: `${uiElement.x}px`,
          top: `${uiElement.y}px`,
          width: `${uiElement.width}px`,
          height: `${uiElement.height}px`,
          transform: `rotate(${uiElement.rotation || 0}deg)`, 
          textAlign: uiElement.alignment || "left",
          overflow: "hidden",
          zIndex: uiElement.zIndex || 1000, // Default zIndex
        }}
      >
        <img
          src={uiElement.image_url}
          alt={`.`}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
    ));
  };
  
  const renderSelectedItems = () => {
    return Object.keys(selectedItems).map((assetId) => {
      const item = selectedItems[assetId];
      const settings = item.renderingSettings;
      
      if (!settings) return null;
  
      return (
        <div
          key={assetId}
          style={{
            position: "absolute",
            left: `${settings.x}px`,
            top: `${settings.y}px`,
            width: `${settings.width}px`,
            height: `${settings.height}px`,
            transform: `rotate(${settings.rotation || 0}deg)`,
            textAlign: settings.alignment,
            overflow: "hidden",
            zIndex: `${settings.zIndex}` || 1,
          }}
        >
          <img
            src={item.result_image_url[0]} // Assuming the creation has an image URL
            alt={`.`}
            style={{
              width: "100%",
              height: "100%"
            }}
          />
        </div>
      );
    });
  };

  return (
    <div
      className={`${styles.shop} ${
        generationErrorMessage ? styles.fadeIn : ""
      }`}
    >
      <div className={styles.background}>
        <img src={organizationData?.app_bg} alt="Background" />
      </div>
      <Card className={styles.card}>
        <h1>{artifact?.display_name}</h1>
        <div style={{ textAlign: "center" }}>
          <div
            style={{ display: "flex", justifyContent: "center"}}
          >
              <div className={styles.inputButtonContainer}>
                <PromptBox
                  canGenerate={true}
                  OnGenerateClicked={handleGenerateAllAssets}
                  onPromptChange={setCurrentPrompt}
                  artifact={artifact}
                  numberOfImagesPerPrompt={3}
                />
              </div>
          </div>
        </div>
      </Card>
      <br></br>
      <div style={{ backgroundColor: "#fcf8ff", borderRadius: 8, padding: 20 }}>
        <div style={{ display: "flex", gap: 20 }}>
          {/* Section 1 */}
          <div style={{ flexBasis: "12%", flexShrink: 0}}>
            <h3 style={{ marginBottom: 10 }}>Components</h3>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 8,
                padding: 10,
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              {assets.map((asset, index) => (
                <div
                  key={index}
                  className={`${styles.toggleButton} ${
                    selectedAssetIndex === index ? styles.active : ""
                  } ${asset.hidden ? styles.disabled : ""}`}
                  onClick={() => !asset.hidden && toggleAsset(index)}
                  style={{
                    pointerEvents: asset.hidden ? "none" : "auto",
                    borderRadius: 8,
                    padding: 12,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor:
                    selectedAssetIndex === index ? "#dafbf8" : "transparent",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  {artifact?.recipe?.[index]?.icon_url && (
                    <img
                      src={artifact.recipe[index].icon_url}
                      style={{ width: 25, height: 25, marginBottom: 8 }}
                      alt="icon"
                    />
                  )}
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: "inherit",
                    }}
                  >
                    {artifact?.recipe?.[index]?.display_name ||
                      `Asset ${index + 1}`}
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* Section 2 */}
          {/* <div style={{ width: "380px"}}> */}
          <div style={{ flexBasis: "25%", flexShrink: 0}}>
            <h3 style={{ marginBottom: 10 }}>Assets</h3>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 8,
                padding: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 4,
                maxHeight: "900px",      // <--- limit height
                overflowY: "auto",       // <--- enable scroll
                overflowX: "hidden",
              }}
            >
              {selectedAsset ? (
                (creationsByAssetId[selectedAsset.recipe_unique_id] || []).length > 0 ? (
                  <>
                  {(creationsByAssetId[selectedAsset.recipe_unique_id] || []).map(
                  (creation, cIndex) => {
                    const isSelected = Object.values(selectedItems).some(
                      (item) =>
                        item.creation_id === creation.creation_id &&
                        item.creation_sub_id === creation.creation_sub_id
                    );

                    return (
                      <React.Fragment key={cIndex}>
                        <CreationCard
                          item={creation}
                          key={cIndex}
                          className={cn(styles.product, {
                            [styles.selected]: isSelected, // Add the `selected` style conditionally
                          })}
                          showPrompt={false}
                          onShowPreview={() =>
                            openModal(
                              creationsByAssetId[selectedAsset.recipe_unique_id],
                              cIndex,
                              artifact
                            )
                          }
                          onLikeDislike={
                            creation
                              ? () =>
                                  toggleLikeStatus(
                                    creation,
                                    creation.creation_id,
                                    creation.creation_sub_id
                                  )
                              : undefined
                          }
                        />
                        {creation?.status === "completed" && (
                          <button
                            className={styles.chooseButton}
                            onClick={() =>
                              handleSelectItem(creation, selectedAsset.recipe_unique_id)
                            }
                          >
                            Choose
                          </button>
                        )}
                      </React.Fragment>
                    );
                  }
                )}
                  {showGenerateMore && (
                    <button
                      className={styles.generateMoreButton}
                      onClick={() =>
                        handleGenerateSingleAsset({
                          prompt: currentPrompt,        // use the same prompt 
                          numImagesPerPrompt: 3,
                          enrichPromptByChatGPTFlag: enrichPromptByChatGPTFlag,
                        })
                      }
                    >
                      Generate More
                    </button>
                  )}
              </>
                ) : (
                <p>No creations yet for this asset.</p>
                )
              ) : (
                <p>Please select an asset to see its creations.</p>
              )}
            </div>
          </div>

          {/* Section 3 */}
          <div
            className={styles.section3Container}
            style={{ flexBasis: "58%", flexShrink: 1, overflowX: "auto"}}
          >
              <h3 style={{ marginBottom: 10, marginRight: 30 }}>Preview</h3>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 8,
                  padding: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {zoomButtons()}
                {Object.keys(selectedItems).length > 0 && (
                  <>
                  <button 
                    className={styles.downloadButton}
                    onClick={onDownload}>
                    <img
                      src="https://prod-genielabs-ui-bucket.s3.us-east-2.amazonaws.com/system-design-ui-assets/icons/download_outline_genie_color.svg"
                      alt="Download"
                    />
                  </button>
                  </>
                )}
              {renderTemplate()}
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiAssetsComposition;