import React, { useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import { useAuth0 } from "@auth0/auth0-react";

const OrganizationSwitcher = () => {
    const admins = [
        "roisafran@gmail.com",
        "rois@genielabs.tech",
        "yotam@genielabs.tech",
        "yarden@genielabs.tech",
        "Nadav@genielabs.tech",
        "eyal@genielabs.com",
        "mayabend@genielabs.tech",
        "dor@genielabs.tech",
        "nadav@genielabs.tech",
        "admin@genielabs.tech",
        "wolfyotamps@gmail.com",
        "wolfyotamaws@gmail.com",
        "wolfyotam@gmail.com", 
        "yotamwo@playtika.com",
        "omerlavi96@gmail.com",
    ]; 

    const [organizationMap, setOrganizationMap] = useState({}); // Map to store organization_id by display_name
    const [labels, setLabels] = useState([]); // Array of display names for the dropdown
    const { user } = useAuth0();
    const [showSwitcher, setShowSwitcher] = useState(false);

    // Effect to check if user is an admin
    useEffect(() => {
        const isUserAdmin = async () => {
            try {
                console.log("User Email:", user?.email);
                const response = await ApiService.get(`/is_user_admin?email=${user?.email}`);
                console.log("User Response:", response);
                if (response.data === true) {
                    setShowSwitcher(true);
                }
            } catch (error) {
                console.error("Error updating user organization or logging out", error);
            }
        };

        if (user?.email) {
            isUserAdmin();
        }
    }, [user]);

    // Effect to fetch organizations
    useEffect(() => {
        const fetchOrganizations = async () => {
            if (!(admins.includes(user?.email) && showSwitcher)) {
                setOrganizationMap({});
            }
            try {
                const response = await ApiService.get(`/get_all_organizations_names`);

                if (response.error) {
                    console.error("Error fetching organizations:", response.error);
                } else {
                    const map = response.data;
                    setOrganizationMap(map); // Save the map
                    setLabels(Object.values(map).sort()); // Set display_name values as labels
                }
            } catch (error) {
                console.error("Error fetching organizations", error);
            }
        };

        fetchOrganizations();
    }, []);

    const handleOrganizationChange = async (event) => {
        const selectedLabel = event.target.value; // Get the selected label (display_name)
        // const organizationId = organizationMap[selectedLabel]; // Get the corresponding organization_id
        console.log("Selected Label:", selectedLabel);
        if (!selectedLabel) {
            console.error("No organization selected.");
            return;
        }
        try {
            const url = `/get_organization_id?organization_name=${encodeURIComponent(selectedLabel)}`;
            console.log("Request URL:", url);
            // const response = await ApiService.get(`/get_organization_id`, {
            //     params: { organization_name: selectedLabel }, 
            // });
            const response = await ApiService.get(url);
            console.log("Response:", response);
            const organizationId = response?.data?.organization_id;
            console.log("Organization ID:", organizationId);
            
            if (!organizationId) {
                console.error("Organization ID not found for the selected label.");
                return;
            }
    
            // Update the user's organization
            await ApiService.put(`/update_user_organization`, {
                email: user.email,
                organization_id: organizationId,
            });
    
            console.log("Selected Organization ID:", organizationId);
            console.log("User Email:", user?.email);
    
            // Reload the page to apply changes
            window.location.reload();
        } catch (error) {
            console.error("Error during organization change process", error);
        }
    };

    return (
        showSwitcher && admins.includes(user?.email) ? (
            <select
                onChange={handleOrganizationChange} // Handle selection change
                style={{ width: "200px", height: "37px", borderRadius: "5px", fontSize: "16px" }}
            >
                <option value="">Organization Switcher</option>
                {labels.map((label) => (
                    <option 
                        key={label}
                        value={label}
                    >
                        {label}
                    </option>
                ))}
            </select>
        ) : null
    );
};

export default OrganizationSwitcher;
