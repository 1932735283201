import React, { useState, useRef,useEffect, use } from "react";
import Loader from "../../components/Loader";
import Card from "../../components/Card";
import styles from "./PO1+2.module.sass";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../../components/Icon";
import Control from "../../components/Product/Control";
import ZipCreator from "../../services/ZipCreatorService";
import { toast } from "react-hot-toast";
import { useAuth } from "../../contexts/AuthContext";
import ApiService from "../../services/ApiService";
import PromptBox from "../../components/PromptBox";
import { useLoadingBar } from "../../contexts/LoadingBarContext";
import GenerationService from "../../services/GenerationService";
import {personalOffer1Plus2OfferArtifactId} from "../../consts";
import { set } from "date-fns";

const RollingOfferCard = ({ imageUrl, children, width, showItems, creation, style }) => {
  // const style = {
  //   backgroundImage: imageUrl ? `url(${imageUrl})` : "none",
  //   backgroundSize: "100% 100%",
  //   width: width ? width : 270,
  //   height: 136,
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   opacity: 1,
  // };
  style = {...style, backgroundImage: imageUrl ? `url(${imageUrl})` : "none", width: width ? width : 300,}
  return (
    <div>
      <div style={style}>
        {children}
        {imageUrl ? (
          showItems ? (
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/images/rollingOfferItems.png`}
                alt="Items"
                style={{ width: 200, }}
              />
            </div>
          ) : (
            ""
          )
        ) : (
          <Loader timestamp_creation_id={(creation?.timestamp_creation_id?.split("_")[0])} time_of_click={(Date.now() / 1000)} />
        )}
      </div>
    </div>
  );
};

export const PersonalOffer1Plus2Option = ({ creation, onLike, onDownload }) => {
  console.log("PO1+2 creation:", creation);
  return (
    <div
      className="rollingOfferCard"
      style={{
        height: "633px",
        minWidth: "292.5px",
        paddingTop: 60,
        width: "auto",
        gap: 0,
        display: "flex",
        flexDirection: "column",
        borderRadius: 10,
        backgroundSize: "100% 100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${`${process.env.PUBLIC_URL}/images/RollingTripleOfferBg.png`})`,
      }}
    >
      <RollingOfferCard
        imageUrl={
          creation?.components_details?.title
            ? creation?.components_details?.title?.result_image_url[0]
            : ""
        }
        showItems={false}
        creation={creation}
        style={{
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
          height: 136,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: 1,
          // backgroundColor: "red"
        }}
      />
      <RollingOfferCard
        imageUrl={
          creation?.components_details?.body
            ? creation?.components_details?.body?.result_image_url[0]
            : ""
        }
        showItems={false}
        creation={creation}
        style={{
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
          height: 330,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: 1,
          // backgroundColor: "red"
        }}
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: 35 }}>
        {creation?.status == "completed" && (
          <Control
            className={styles.control}
            item={creation}
            onLikeDislike={onLike}
            onDownload={onDownload}
          />
        )}
      </div>
    </div>
  );
};

const GenerateOffer1Plus2Screen = () => {
  
  const [creations, setCreations] = useState([]);
  const location = useLocation();
  const [canGenerate, setCanGenerate] = useState(true);
  const navigate = useNavigate();
  const { progress, setProgress } = useLoadingBar();
  const { organizationData } = useAuth();
  const artifactId = personalOffer1Plus2OfferArtifactId
  const [artifact, setArtifact] = useState(null);
  const [currentPrompt, setCurrentPrompt] = useState("");
  
  // const [pollingStatus, setPollingStatus] = useState([]);
  // const [generalPrompt, setPrompt] = useState("");
  // const [contentVisible, setContentVisible] = useState(false);
  // const pollingIntervals = useRef({});
  // const [creationsList, setCreationsList] = useState([]);
  // const [showCreations, setShowCreations] = useState(false);
  // const pathname = location.pathname;
  // const [creations, setCreations] = useState([
  //   {
  //     "additional_creation_id": null,
  //     "additional_creation_sub_id": null,
  //     "artifact_id": "be13d341-ff12-471d-aa2b-8e872b3727b6",
  //     "based_on_image_url": null,
  //     "components_details": {
  //         "body": {
  //             "additional_creation_id": null,
  //             "additional_creation_sub_id": null,
  //             "artifact_id": "69a9626e-cb2c-4e62-831e-50daf3d4afdc",
  //             "based_on_image_url": null,
  //             "components_map": {},
  //             "creation_id": "978e43b5-be86-405c-8b62-474893657e13",
  //             "creation_sub_id": "2",
  //             "creation_type": "component",
  //             "creator_name": "roisafran@gmail.com",
  //             "dimensions": null,
  //             "downloaded": false,
  //             "inspired_by": null,
  //             "is_layout": false,
  //             "liked": false,
  //             "mask_image_url": null,
  //             "master_color": null,
  //             "organization_id": "INNPLAY-0c0dc5",
  //             "post_enrichment_prompt": "",
  //             "previous_creation_id": null,
  //             "previous_creation_sub_id": null,
  //             "prompt": "The background displays bustling New York streets with towering skyscrapers, yellow taxis, and vibrant street vendors under a clear blue sky.",
  //             "raw_prompt": "",
  //             "reference_type": "No Reference",
  //             "result_image_url": [
  //                 "https://genielabs-organizations-data-stage.s3.amazonaws.com/INNPLAY-0c0dc5/creations/978e43b5-be86-405c-8b62-474893657e13_2_0.png"
  //             ],
  //             "seed": null,
  //             "sketch_image_url": null,
  //             "status": "completed",
  //             "thumbnail_image_url": "",
  //             "timestamp": "1735491620812119000",
  //             "timestamp_creation_id": "1735491620812119000_978e43b5-be86-405c-8b62-474893657e13_2"
  //         },
  //         "title": {
  //             "additional_creation_id": null,
  //             "additional_creation_sub_id": null,
  //             "artifact_id": "ba9bb0a7-c1be-4483-8289-b9f083fd7cc9",
  //             "based_on_image_url": null,
  //             "components_map": {},
  //             "creation_id": "87577ae6-911d-451d-ad49-f8fb2708d380",
  //             "creation_sub_id": "2",
  //             "creation_type": "component",
  //             "creator_name": "roisafran@gmail.com",
  //             "dimensions": null,
  //             "downloaded": false,
  //             "inspired_by": null,
  //             "is_layout": false,
  //             "liked": false,
  //             "mask_image_url": null,
  //             "master_color": null,
  //             "organization_id": "INNPLAY-0c0dc5",
  //             "post_enrichment_prompt": "",
  //             "previous_creation_id": null,
  //             "previous_creation_sub_id": null,
  //             "prompt": "The background displays bustling New York streets with towering skyscrapers, yellow taxis, and vibrant street vendors under a clear blue sky.",
  //             "raw_prompt": "",
  //             "reference_type": "No Reference",
  //             "result_image_url": [
  //                 "https://genielabs-organizations-data-stage.s3.amazonaws.com/INNPLAY-0c0dc5/creations/87577ae6-911d-451d-ad49-f8fb2708d380_2_0.png"
  //             ],
  //             "seed": null,
  //             "sketch_image_url": null,
  //             "status": "completed",
  //             "thumbnail_image_url": "",
  //             "timestamp": "1735491621882856000",
  //             "timestamp_creation_id": "1735491621882856000_87577ae6-911d-451d-ad49-f8fb2708d380_2"
  //         }
  //     },
  //     "components_map": {
  //         "body": "978e43b5-be86-405c-8b62-474893657e13",
  //         "title": "87577ae6-911d-451d-ad49-f8fb2708d380"
  //     },
  //     "creation_id": "03b04083-29da-4cae-b2f9-6940e317de04",
  //     "creation_sub_id": "2",
  //     "creation_type": "personal-offer-1+2",
  //     "creator_name": "roisafran@gmail.com",
  //     "dimensions": null,
  //     "downloaded": false,
  //     "inspired_by": null,
  //     "is_layout": false,
  //     "liked": false,
  //     "mask_image_url": null,
  //     "master_color": null,
  //     "organization_id": "INNPLAY-0c0dc5",
  //     "post_enrichment_prompt": "",
  //     "previous_creation_id": null,
  //     "previous_creation_sub_id": null,
  //     "prompt": "The background displays bustling New York streets with towering skyscrapers, yellow taxis, and vibrant street vendors under a clear blue sky.",
  //     "raw_prompt": "",
  //     "reference_type": "No Reference",
  //     "result_image_url": [
  //         "https://genielabs-organizations-data-stage.s3.amazonaws.com/INNPLAY-0c0dc5/creations/978e43b5-be86-405c-8b62-474893657e13_2_0.png",
  //         "https://genielabs-organizations-data-stage.s3.amazonaws.com/INNPLAY-0c0dc5/creations/87577ae6-911d-451d-ad49-f8fb2708d380_2_0.png"
  //     ],
  //     "seed": null,
  //     "sketch_image_url": null,
  //     "status": "completed",
  //     "thumbnail_image_url": "",
  //     "timestamp": "1735491620100709000",
  //     "timestamp_creation_id": "1735491620100709000_03b04083-29da-4cae-b2f9-6940e317de04_2"
  // }
  // ]);
  // const [currentPrompt, setCurrentPrompt] = useState(() => {
  //   return JSON.parse(localStorage.getItem("currentPrompt")) ?? "";
  // });
  useEffect(() => {
        async function fetchArtifact() {
          if (artifactId) {
            try {
              const ARTIFACT_ENDPOINT = `/get_artifact/${artifactId}`;
              setProgress(30);
              const artifactResponse = await ApiService.get(ARTIFACT_ENDPOINT);
              setProgress(60);
              setArtifact(artifactResponse.data.artifact);
              
              setProgress(100);
            } catch (error) {
              console.error("Error fetching artifact:", error);
              setProgress(0);
            }
          }
        }
    
        fetchArtifact();
  }, [artifactId]);
  
  useEffect(() => {
    console.log("PO1+2 - prompt: ", currentPrompt);
  }, [currentPrompt]);

  // const handleOnRegenerateClicked = async () => {
  //   setCanGenerate(false);

  //   const requestData = {
  //     artifact_id: artifactId,
  //     prompt: [activePrompt, passivePrompt],
  //     artifact_type: "offer",
  //     direction: "left",
  //     input_image_url:
  //       creations[0].components_details?.title.based_on_image_url,
  //     fixed_title: creations[0].components_details?.title.result_image_url,
  //   };
  //   console.log(requestData);
  //   const response = await ApiService.post("/start_generation", requestData);
  //   fetchArtifactAndProducts();
  // };

  useEffect(() => {
    if (
      creations?.length > 0 &&
      creations.every((creation) => creation?.status === "completed")
    ) {
      setCanGenerate(true);
    } else {
      setCanGenerate(false);
    }
  }, [creations]);

  const fetchArtifactAndProducts = async () => {
    try {
      // if creations and all of them are not completed
      if (
        creations?.length == 0 ||
        creations?.some((creation) => creation?.status !== "completed")
      ) {
        const limit = 3;
        const creationsResponse = await ApiService.get(
          `/get_creations_based_on_artifact_id?artifact_id=${artifactId}&limit=${limit}`
        );
        setCreations(creationsResponse.data);
        console.log("PO1+2 creationsResponse:", creationsResponse);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch creation data. Please try again later.");
      setCanGenerate(true);
    }
  };

  // const fetchArtifactAndProducts = async () => {
  //   try {
  //     const limit = 3; // Adjust the limit as needed
  //     const creationsResponse = await ApiService.get(
  //       `/get_creations_based_on_artifact_id?artifact_id=${artifactId}&limit=${limit}`
  //     );
  //     setCreations(creationsResponse.data); // Update the creations state
  //   } catch (error) {
  //     console.error("Error fetching creations:", error);
  //     toast.error("Failed to fetch creations. Please try again later.");
  //     setCanGenerate(true); // Ensure generation button is enabled even on error
  //   }
  // };
  

  useEffect(() => {
    const interval = setInterval(() => {
      fetchArtifactAndProducts();
    }, 10000);

    return () => clearInterval(interval);
  }, [artifactId]);

  const onDownload = async (creation) => {
    console.log("Downloading creation:", creation);
    const files = [
      {
        name: `${creation.creation_id}_${creation.creation_sub_id}_title.png`,
        url: creation.components_details.title.result_image_url[0],
      },
      {
        name: `${creation.creation_id}_${creation.creation_sub_id}_body.png`,
        url: creation.components_details.body.result_image_url[0],
      },
    ].filter(file => file.url);
    if (files.length === 0) {
      toast.error("No files available for download.");
      return;
    }
    toast
      .promise(
        ZipCreator.createZipFile(
          files,
          `PO1+2_${creation.creation_id}_${creation.creator_name}`
        ),
        {
          loading: "Downloading Offer...",
          success: "Downloading was successful!",
          error: "Failed to create zip file. Please try again later.",
        }
      )
      .then(async () => {
        try {
          ApiService.post(
            `/download_creation/${creation.creation_id}/${creation.creation_sub_id}`
          );
        } catch (error) {
          console.error("Failed to toggle like status:", error);
        }
      })
      .catch((error) => {
        console.error("Error during zip creation or download:", error);
      });
  };

  const onLike = async (creation) => {
    let { creation_sub_id, creation_id } = creation;

    const index = creations.findIndex(
      (c) =>
        c.creation_id === creation_id && c.creation_sub_id === creation_sub_id
    );
    if (index === -1) {
      toast.error("Creation not found. Please try again later.");
      return;
    }

    const updatedCreations = creations.map((c, i) => {
      if (i === index) {
        return { ...c, liked: !c.liked };
      }
      return c;
    });
    // setCreations(updatedCreations);

    toast
      .promise(
        ApiService.post(
          `/like_or_dislike_creation/${creation_id}/${creation_sub_id}`,
          { liked: updatedCreations[index].liked }
        ),
        {
          loading: "Updating like status...",
          success: () => {
            setCreations(updatedCreations);
            return updatedCreations
              ? "Liked successfully!"
              : "Unliked successfully!";
          },
          // console.log(`Liked status updated to:`, updatedCreations[index].liked);
          error: "Failed to update like status. Please try again later.",
        }
      )
      .catch((error) => {
        console.error(error);
      });
  };

  const titleCase = (str) => {
    return str
      .replace(/-/g, " ") // Replace all hyphens with spaces
      .toLowerCase() // Convert the string to lowercase
      .split(" ") // Split the string into an array of words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the array back into a string
  };

  // const resetAll = () => {
  //   setContentVisible(false);
  //   setCreationsList([]); // Change this from setRecentCreations([])
  //   setCanGenerate(true);
  //   localStorage.removeItem("currentPrompt");
  //   setPrompt("");
  //   setCurrentPrompt("");
  //   setPollingStatus([]);
  // };

  const handleGeneratePersonalOffer1Plus2 = async () => {
    const response = await GenerationService.GeneratePersonalOffer1Plus2(
      currentPrompt
    );
    // console.log("handleGeneratePersonalOffer1Plus2 response:", response);
    // navigate("/personal-offer-1+2");
  };

  // const handleGeneratePersonalOffer1Plus2 = async () => {
  //   setCanGenerate(false); // Disable generation button
  //   setShowCreations(false); // Hide creations while generating
  
  //   try {
  //     // Start the generation process
  //     await GenerationService.GeneratePersonalOffer1Plus2(currentPrompt);
  
  //     // Fetch the initial creations to show loading states
  //     await fetchArtifactAndProducts();
  //   } catch (error) {
  //     console.error("Error generating Personal Offer 1+2:", error);
  //     toast.error("Failed to generate. Please try again later.");
  //     setShowCreations(false);
  //   } finally {
  //     setCanGenerate(true); // Re-enable generation button
  //   }
  // };


  return (
    <div className={`${styles.shop} ${styles.fadeIn}`}>
      <div className={styles.background}>
        <img src={organizationData?.app_bg} alt="Background" />
      </div>
      <Card className={styles.card}>
        <h1>{titleCase(`Personal Offer 1+2`)}</h1>
        <div style={{ textAlign: "right" }}>
        </div>
        <br />
          <div
            className={styles.details}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className={styles.wrap}>
              <div className={styles.inputButtonContainer}>
                  <PromptBox 
                    artifact={artifact}
                    canGenerate={true}
                    OnGenerateClicked={handleGeneratePersonalOffer1Plus2}
                    onPromptChange={setCurrentPrompt}
                    numberOfImagesPerPrompt={3}
                  />
              </div>
            </div>
          </div>
        <br></br>
        {/* Creations Section */}
      {/* {showCreations && ( */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center", gap: 20 }}>
            {creations?.map((creation, index) => (
              <PersonalOffer1Plus2Option
                key={index}
                creation={creation}
                onLike={() => onLike(creation)}
                onDownload={() => onDownload(creation)}
              />
            ))}
          </div>
        </div>
      {/* )} */}
    </Card>
  </div>
);
};

export default GenerateOffer1Plus2Screen;
