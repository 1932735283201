export const tripleRollingOfferArtifactId = "4baa24aa-a711-4f3d-907b-b88fe27d0d29";
export const personalOffer6ArtifactId = "7f9ad9b4-85a9-47d0-ad6f-21533721e85e";
export const personalOffer1Plus2OfferArtifactId = "be13d341-ff12-471d-aa2b-8e872b3727b6";

export const REFERENCE_TYPES = {
  IMAGE_VARIATIONS: "Image Variations",
  INPAINTING: "Inpainting",
};

export const tileToIndexMapping = {
  8: 0,
  12: 1,
  15: 2,
  20: 3,
  30: 4,
  40: 5,
  50: 6,
};
