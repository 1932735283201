import React, { useState, useEffect, useMemo, use } from "react";
import cn from "classnames";
import { useParams, useLocation } from "react-router-dom";
import ApiService from "../../services/ApiService";
import Profile from "./Profile";
import Card from "../../components/Card";
// import CreationCard from "../../components/Product";
import NewCreationCard from "../../components/NewCreationCard";
import { toast } from "react-hot-toast";
import { useLoadingBar } from "../../contexts/LoadingBarContext";
import GenerationService from "../../services/GenerationService";
import { useModalPreview } from "../../contexts/ModalPreviewContext";
import { useAuth } from "../../contexts/AuthContext";
import styles from "./NewGallery.module.sass";
import Loader from "../../components/Loader";
import NestedCheckboxTree from "../../components/FiltersDropdown";
import Dropdown from "../../components/Dropdown";
import { styleFilter, dateFilter, artistFilter} from "./treeData";

const navigation = []; // Define your navigation items here

const complexComponents = ["triple-rolling-offer", "personal-offer-6"];

const NewGallery = () => {
  const { openModal } = useModalPreview();
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [hasMoreCreations, setHasMoreCreations] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [artifact, setArtifact] = useState(null);
  const [creations, setCreations] = useState([]);
  const { artifactId, creationType } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const artifactIdFromQuery = queryParams.get("artifact_id");
  const [loadingMore, setLoadingMore] = useState(false); 
  const [currentViewingCreation, setCurrentViewingCreation] = useState(null);
  const [canGenerate, setCanGenerate] = useState(true);
  const isNotSimple = complexComponents.includes(creationType);
  const { progress, setProgress } = useLoadingBar();
  const { organizationData } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({
    styles: [],
    date: [],
    artists: [],
    tags: [],
  });
  const [activeFilter, setActiveFilter] = useState(null);
  const [styleTreeData, setStyleTreeData] = useState([]);
  const [userTreeData, setUserTreeData] = useState([]);

  const fetchArtifact = async (wanted_creation_id) => {
    try {
      const response = await ApiService.get(`/get_artifact/${wanted_creation_id}`);
      const passedArtifact = response.data.artifact;
      console.log("Fetched artifact:", passedArtifact);
      return passedArtifact // Return the fetched artifact
    } catch (error) {
      console.error("Error fetching artifact:", error);
      toast.error("Failed to fetch artifact, please try again later.");
      throw error; // Rethrow the error for proper handling
    }
  };
  
  useEffect(() => {
    const fetchStyleFilter = async () => {
      try {
        const organizationId = organizationData?.organization; // Ensure this is populated
        const response = await ApiService.get(
          `/create_style_filter_by_organization?organization_id=${organizationId}`
        );
        console.log("Style filter data:", response);
        setStyleTreeData(response);
      } catch (error) {
        console.error("Error fetching style filter data:", error);
      }
    };

    if (organizationData?.organization) {
      fetchStyleFilter();
    }
  }, [organizationData]);

  useEffect(() => {
    const fetchUserFilter = async () => {
        try {
            const organizationId = organizationData?.organization; // Ensure this is populated
            if (!organizationId) {
                console.error("Organization ID is missing.");
                return;
            }

            const response = await ApiService.get(
                `/create_user_filter_by_organization?organization_id=${organizationId}`
            );

            if (response.error) {
                console.error("Error fetching user filter:", response.error);
            } else {
                console.log("User filter data:", response);
                setUserTreeData(response);
            }
        } catch (error) {
            console.error("Error fetching user filter data:", error);
        }
    };

    fetchUserFilter();
  }, [organizationData]);

  // ! when outside click occur, close dropdown #TODO in the future
  // useEffect(() => {
  //   if (activeFilter) {
  //     document.addEventListener("mousedown", handleOutsideClick);
  //   } else {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   }

  //   return () => document.removeEventListener("mousedown", handleOutsideClick);
  // }, [activeFilter]);

  // const handleOutsideClick = (event) => {
  //   // Close dropdown if clicked outside any active filter
  //   if (!document.querySelector(`#${activeFilter}`)?.contains(event.target)) {
  //     setActiveFilter(null);
  //   }
  // };

  const handleSelectionChange = (filterKey, newValues) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterKey]: newValues,  // only update one section
    }));
  };
  
  const updateCreation = (creationId, creationSubId, updatedProperties) => {
    setCreations((prevCreations) =>
      prevCreations.map((creation) =>
        creation.creation_id === creationId &&
        creation.creation_sub_id === creationSubId
          ? { ...creation, ...updatedProperties }
          : creation
      )
    );
  };

  const findCreationIndex = (creationId, creationSubId) => {
    const index = creations.findIndex(
      (c) => c.creation_id === creationId && c.creation_sub_id == creationSubId
    );
    if (index === -1) {
      toast.error("Creation not found. Please try again later.");
      return null;
    }
    return index;
  };
  const findCreation = (creationId, creationSubId) => {
    const index = findCreationIndex(creationId, creationSubId);
    return index !== -1 ? creations[index] : null;
  };

  const regenerateCreation = async (creationId, creationSubId) => {
    await GenerationService.RegenerateCreation(
      findCreation(creationId, creationSubId)
    );
    fetchArtifactAndCreations();
  };

  const generateNewVariations = async (creationId, creationSubId) => {
    await GenerationService.GenerateNewVariations(
      findCreation(creationId, creationSubId)
    );
    fetchArtifactAndCreations();
  };

  const toggleLikeStatus = (creationId, creationSubId) => {
    const index = findCreationIndex(creationId, creationSubId);
    if (index === null) return;

    const updatedStatus = !creations[index].liked;

    toast
      .promise(
        ApiService.post(
          `/like_or_dislike_creation/${creationId}/${creationSubId}`,
          { liked: updatedStatus }
        ),
        {
          loading: "Updating like status...",
          success: () => {
            updateCreation(creationId, creationSubId, { liked: updatedStatus });
            return updatedStatus
              ? "Liked successfully!"
              : "Unliked successfully!";
          },
          error: "Failed to update like status. Please try again later.",
        }
      )
      .catch((error) => {
        console.error(error);
      });
  };

  const generateCreations = ({
    prompt,
    numImagesPerPrompt,
    isRemoveBackground,
    enrichPromptByChatGPTFlag,
    creationId = null,
    image,
    referenceType,
    dimensionsRatio,
    settings,
    masterColor,
    isLayout
  }) => {
    const timestamp = Date.now();
    setCanGenerate(false);

    toast.promise(
      (async () => {
        console.log("WE MOVED FROM HERE");
        const response = await GenerationService.startGeneration({
          prompt,
          numberOfImages: numImagesPerPrompt,
          isRemoveBackground,
          enrichPromptByChatGPTFlag,
          creationId,
          image,
          referenceType,
          dimensionsRatio,
          artifact,
          artifactId,
          settings,
          masterColor,
          isLayout
        });

        if (response.error) {
          throw new Error(response.error);
        }

        await fetchArtifactAndCreations();
        return response;
      })(),
      {
        loading: "Generating creations...",
        success: () => {
          setCanGenerate(true);
          return "Creations generated successfully!";
        },
        error: (err) => {
          console.error("Failed to initiate generation:", err);
          setCreations((prevCreations) =>
            prevCreations.filter(
              (creation) =>
                !creation.creation_id.startsWith("temp_" + timestamp)
            )
          );
          setCanGenerate(true);
          return "Failed to initiate generation. Please try again later.";
        },
      }
    );
  };

  useEffect(() => {
    setArtifact(null);
    setCreations([]);
    // fetchArtifactAndCreations(null, true);
    fetchAllLikedCreations();
  }, [artifactId, creationType]);

  useEffect(() => {
    if (currentViewingCreation != null) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [currentViewingCreation]);

  const loadMoreCreations = async () => {
    if (lastEvaluatedKey && !loadingMore) {
      setLoadingMore(true);
      setProgress(50);
      try {
        console.log("Loading more creations...");
        // Pass `true` for the `append` parameter to ensure new creations are added to the end
        await fetchArtifactAndCreations(lastEvaluatedKey, true);
      } catch (error) {
        toast.error("Failed to load more creations, please try again later.");
        console.error("Error loading more creations:", error);
      } finally {
        setLoadingMore(false);
        setProgress(100);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkStatusOfPendingCreations();
    }, 10000);

    console.log("creations", creations);
    return () => clearInterval(interval);
  }, [creations]);

  const checkStatusOfPendingCreations = async () => {
    const pendingCreations = creations?.filter(
      (creation) => creation.status === "pending"
    );
    if (!pendingCreations || pendingCreations.length === 0) {
      return;
    }

    const payload = pendingCreations.map((creation) => ({
      creation_id: creation.creation_id,
      creation_sub_id: creation.creation_sub_id,
    }));

    try {
      const response = await ApiService.post("/check_status", payload);
      console.log(response);
      const updatedCreations = response.results;

      setCreations((prevCreations) =>
        prevCreations.map((c) => {
          const updatedCreation = updatedCreations.find(
            (uc) =>
              uc.creation_id === c.creation_id &&
              uc.creation_sub_id === c.creation_sub_id
          );
          return updatedCreation && !updatedCreation.error
            ? { ...c, ...updatedCreation.data }
            : c;
        })
      );
    } catch (error) {
      console.error("Error checking status:", error);
    }
  };

  const fetchArtifactAndCreations = async (
    startKey = null,
    append = false 
  ) => {
    const ARTIFACT_ENDPOINT = `/get_artifact/${artifactId}`;
    const CREATIONS_BASED_ON_ARTIFACT_ENDPOINT = `/get_creations_based_on_artifact_id`;
    const LIKED_CREATIONS_ENDPOINT = `/get_liked_creations`;

    const handleError = (message) => {
      toast.error(message);
      setProgress(100);
    };

    const buildUrlWithStartKey = (base, params = {}) => {
      let url = base;
      const queryParams = new URLSearchParams(params);
      if (startKey) queryParams.append("start_key", startKey);
      if (queryParams.toString()) url += `?${queryParams.toString()}`;
      return url;
    };

    const fetchCreations = async (artifact, creationType, append = false) => {
      let url;
      if (artifact) {
        url = buildUrlWithStartKey(CREATIONS_BASED_ON_ARTIFACT_ENDPOINT, {
          artifact_id: artifactId,
          limit: 20,
        });
      } else if (creationType) {
        url = buildUrlWithStartKey(LIKED_CREATIONS_ENDPOINT, {
          creation_type: creationType,
        });
      } else if (artifactIdFromQuery) {
        url = buildUrlWithStartKey(LIKED_CREATIONS_ENDPOINT, {
          artifact_id: artifactIdFromQuery,
        });
      } else {
        url = buildUrlWithStartKey(LIKED_CREATIONS_ENDPOINT);
      }

      try {
        const response = await ApiService.get(url);

        const newCreations = response.data;
        setLastEvaluatedKey(response.next_start_key);
        setHasMoreCreations(!!response.next_start_key);

        setCreations((prevCreations) => {
          let updatedCreations = [...prevCreations];
          let newUniqueCreations = [];

          newCreations.forEach((newCreation) => {
            const existingIndex = updatedCreations.findIndex(
              (creation) =>
                creation.creation_id === newCreation.creation_id &&
                creation.creation_sub_id === newCreation.creation_sub_id
            );

            if (existingIndex !== -1) {
              // Update existing creation
              updatedCreations[existingIndex] = {
                ...updatedCreations[existingIndex],
                ...newCreation,
              };
            } else {
              newUniqueCreations.push(newCreation);
            }
          });

          // Append or prepend the new unique creations
          newUniqueCreations.forEach((newCreation) => {
            if (append) {
              updatedCreations.push(newCreation);
            } else {
              updatedCreations.unshift(newCreation);
            }
          });

          return updatedCreations;
        });
      } catch (error) {
        console.error("Error fetching creations:", error);
        handleError("Failed to fetch creations, please try again later.");
      }
    };

    try {
      if (artifactId) {
        //Asset Screen
        const artifactResponse = await ApiService.get(ARTIFACT_ENDPOINT);
        setProgress(60);
        setArtifact(artifactResponse.data.artifact);

        if (artifactResponse.data.artifact) {
          await fetchCreations(artifactResponse.data.artifact, null, append);
        } else {
          setCreations([]);
        }
      } else {
        //Gallery: fetch all liked
        await fetchCreations(null, null, append);
      }
    } catch (error) {
      handleError("Failed to fetch artifact, please try again later.");
    } finally {
      setProgress(100);
    }
  };

  const fetchAllLikedCreations = async () => {
    const LIKED_CREATIONS_ENDPOINT = `/get_liked_creations`;
  
    const buildUrlWithStartKey = (base, params = {}) => {
      const queryParams = new URLSearchParams();
      Object.entries(params).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          queryParams.append(key, value);
        }
      });
      return `${base}?${queryParams.toString()}`;
    };
  
    let allCreations = [];
    let nextKey = null;
  
    try {
      do {
        const url = buildUrlWithStartKey(LIKED_CREATIONS_ENDPOINT, {
          start_key: nextKey,
          limit: 20,
        });
  
        console.log("Fetching creations from:", url);
  
        const response = await ApiService.get(url);
        console.log("Fetched data:", response.data);
        console.log("Next start key:", response.next_start_key);
        
        // If response is empty, break the loop
        if (!response.data || response.data.length === 0) {
          console.log("No more creations to fetch.");
          break;
        }
        // if (response.data.length > 0) {
        allCreations = [...allCreations, ...response.data];
        setCreations((prevCreations) => [...prevCreations, ...response.data]);
        // }
        console.log("All creations so far:", allCreations);
        nextKey = response.next_start_key; // Update the key for pagination
      } while (nextKey);
  
      setCreations(allCreations);
    } catch (error) {
      console.error("Error fetching all liked creations:", error);
      toast.error("Failed to fetch liked creations, please try again later.");
    }
  };

  const filteredCreations = useMemo(() => {
    const query = searchQuery.toLowerCase();
    return creations.filter((creation) => {
      // Check "styles"
      const passStylesCheck = (() => {
        // If no styles are chosen, pass automatically
        if (!selectedFilters.styles || selectedFilters.styles.length === 0) {
          return true;
        }
        // Otherwise, do your partial text match logic
        // const creationType = (creation.creation_type || "").toLowerCase();
        // const artifactDisplayName = (creation?.artifact_display_name || "").toLowerCase();
        // const creationType = (creation.creation_type || "").toLowerCase().replace(/-/g, " ");
        // const creatorName = (creation.creator_name || "").toLowerCase();
        const artifactDisplayName = typeof creation?.artifact_display_name === 'string' ? creation.artifact_display_name.toLowerCase() : "";
        const creationType = typeof creation.creation_type === 'string' ? creation.creation_type.toLowerCase() : "";
        const creatorName = typeof creation.creator_name === 'string' ? creation.creator_name.toLowerCase() : "";
  
        // If ANY chosen style is found in ANY field => pass
        return selectedFilters.styles.some((filterVal) => {
          const f = filterVal.toLowerCase();
          return (
            artifactDisplayName.includes(f) ||
            creationType.includes(f) ||
            creatorName.includes(f)
          );
        });
      })();
      const passDateCheck = (() => {
        if (!selectedFilters.date || selectedFilters.date.length === 0) {
          return true; // No filters selected, all creations pass
        }
      
        let creationDate;
        try {
          // Convert creation.timestamp to a JavaScript Date object
          creationDate = new Date(parseInt(creation.timestamp, 10) / 1e6); // Handle nanoseconds to milliseconds
        } catch (err) {
          console.error("Invalid timestamp for creation:", creation, err);
          return false; // Invalid timestamps should fail the check
        }
      
        // Calculate days since creation
        const daysSinceCreation = (Date.now() - creationDate.getTime()) / (1000 * 60 * 60 * 24);
      
        // Iterate over all selected date filters
        return selectedFilters.date.some((filterVal) => {
          if (filterVal === "all Time") {
            return true // "All Time" passes all creations
          }
      
          const daysThreshold = parseInt(filterVal.match(/\d+/)?.[0], 10); // Extract the number from "Last 7 days", "Last 30 days", etc.
          if (!isNaN(daysThreshold)) {
            return daysSinceCreation >= 0 && daysSinceCreation <= daysThreshold;
          }
      
          return false; // Fallback for unknown filters
        });
      })();
      
      // Check "artists"
      const passArtistsCheck = (() => {
        if (!selectedFilters.artists || selectedFilters.artists.length === 0) {
          return true;
        }
        const creatorName = (creation.creator_name || "").toLowerCase();
        return selectedFilters.artists.some((filterVal) => {
          const f = filterVal.toLowerCase();
          return (
            creatorName.includes(f)
          );
        });
      })();
  
      const passSearchQueryCheck = (() => {
        // your existing prompt/creatorName/artifact_display_name logic
        if (!searchQuery) return true;
        
        const prompt = typeof creation?.prompt === 'string' ? creation.prompt.toLowerCase() : "";
        const rawPrompt = typeof creation?.raw_prompt === 'string' ? creation.raw_prompt.toLowerCase() : "";
        const creatorName = typeof creation?.creator_name === 'string' ? creation.creator_name.toLowerCase() : "";
        const artifactDisplayName = typeof creation?.artifact_display_name === 'string' ? creation.artifact_display_name.toLowerCase() : "";
        return (
          prompt.includes(query) ||
          rawPrompt.includes(query) ||
          creatorName.includes(query) ||
          artifactDisplayName.includes(query)
        );
      })();
  
      // Must pass ALL checks
      return passStylesCheck && passDateCheck && passArtistsCheck && passSearchQueryCheck;
    });
  }, [creations, searchQuery, selectedFilters]);

  useEffect(() => {
    console.log("selectedFilters changed =>", selectedFilters);
  }, [selectedFilters]);
  
  const handleRemoveFilterValue = (filterKey, value) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterKey]: prev[filterKey].filter((v) => v !== value),
    }));
  };

  return (
    <div className={`${styles.shop}`}>
      <div className={styles.background}>
        <img src={organizationData?.app_bg} alt="Background" />
      </div>
      <Card className={styles.card}>
        <div className={styles.controlBar} style={{ display: "flex", marginBottom: "10px", alignItems: "center" }}>
          {creations.length > 0 && (
          <>
          <div style={{display: "flex", gap: "10px", position: "relative"}}>
            {/* Style filter */}
            <div style={{ position: "relative", display: "inline-block" }}>
                <button
                  className="button-stroke"
                  style={{
                    backgroundColor: "#ecf5fa",
                    borderRadius: "8px",
                    border: "0px",
                    marginRight: "10px",
                    fontSize: "18px",
                    width: "140px", 
                    fontWeight: "normal"
                  }}
                  onClick={() => setActiveFilter(prev => (prev === "styles" ? null : "styles"))}
                >
                  Styles
                </button>
                {activeFilter === "styles" && (
                    <div
                      style={{
                        position: "absolute",
                        top: "110%",   
                        left: 0,
                        zIndex: 999,   
                        width: "290px"
                      }}
                    >
                      <NestedCheckboxTree onSelectionChange={handleSelectionChange} checkedValuesProp={selectedFilters.styles} filterKey="styles" treeData={styleTreeData} organization={organizationData["organization"]}/>
                    </div>
                  )}
                </div>
                {/* date filter */}
            <div style={{ position: "relative", display: "inline-block" }}>
                <button
                  className="button-stroke"
                  style={{
                    backgroundColor: "#ecf5fa",
                    borderRadius: "8px",
                    border: "0px",
                    marginRight: "10px",
                    fontSize: "18px",
                    width: "150px",
                    fontWeight: "normal"
                  }}
                  onClick={() => setActiveFilter(prev => (prev === "date" ? null : "date"))}
                >
                  Date Range
                </button>
                {activeFilter === "date" && (
                    <div
                      style={{
                        position: "absolute",
                        top: "110%",   
                        left: 0,
                        zIndex: 999,   
                        width: "290px"
                      }}
                    >
                      <NestedCheckboxTree onSelectionChange={handleSelectionChange} checkedValuesProp={selectedFilters.date} filterKey="date" treeData={dateFilter} organization={organizationData["organization"]}/>
                    </div>
                  )}
                </div>
              {/* artists filter */}
            <div style={{ position: "relative", display: "inline-block" }}>
                <button
                  className="button-stroke"
                  style={{
                    backgroundColor: "#ecf5fa",
                    borderRadius: "8px",
                    border: "0px",
                    marginRight: "10px",
                    fontSize: "18px",
                    width: "140px",
                    fontWeight: "normal"
                  }}
                  onClick={() => setActiveFilter(prev => (prev === "artists" ? null : "artists"))}
                >
                  Artists
                </button>
                {activeFilter === "artists" && (
                    <div
                      style={{
                        position: "absolute",
                        top: "110%",   
                        left: 0,
                        zIndex: 999,   
                        width: "290px"
                      }}
                    >
                      <NestedCheckboxTree onSelectionChange={handleSelectionChange} checkedValuesProp={selectedFilters.artists} filterKey="artists" treeData={userTreeData} organization={organizationData["organization"]}/>
                    </div>
                  )}
                </div>
          </div>
          {/* search bar*/}
          <div style={{ marginLeft: "auto", position: "relative" }}>
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={styles.searchInput}
              style={{
                padding: "9px 10px",
                paddingRight: "35px",  
                backgroundColor: "#ecf5fa",
                height: "40px",
                borderRadius: "20px",
                width: "300px",
                fontSize: "18px",
                backgroundImage: `url('/images/svg/search_icon.svg')`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right 10px center",
                backgroundSize: "20px",
              }}
            />
          </div>
          </>
          )}
        </div>
        
        {/* Render selected filter tags below filters */}
        {creations.length > 0 && (
          <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            margin: "10px 0",
          }}
        >
          {Object.keys(selectedFilters).map((filterKey) =>
            selectedFilters[filterKey].map((val) => (
              <div
                key={`${filterKey}-${val}`}
                style={{
                  backgroundColor: "#dac4fc",
                  padding: "6px 10px",
                  borderRadius: "4px",
                  display: "inline-flex",
                  alignItems: "center",
                  fontSize: "16px",
                }}
              >
                <span>{val}</span>
                <button
                  onClick={() => handleRemoveFilterValue(filterKey, val)}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    marginLeft: "6px",
                    fontSize: "16px",
                  }}
                >
                  X
                </button>
              </div>
            ))
          )}
        </div>
        )}

        {/* If there's an artifact, show its Profile above the creations */}
        {artifact && (
          <Profile
            artifact={artifact}
            generateCreations={generateCreations}
            canGenerate={canGenerate}
          />
        )}

        {creations.length === 0 && (
          // <div>
          //   <h1>It looks like you haven't liked anything yet</h1>
          //   <h2>
          //     Click the heart on any creation to like it and see it here!
          //   </h2>
          // </div>
          <div style={{transform: "translateX(45%)"}}>
            <Loader time_of_click={(Date.now() / 1000)}/>
          </div>
        )}

        <div className={styles.control}>
          <div className={styles.nav}>
            {navigation.map((item, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: index === activeIndex,
                })}
                onClick={() => setActiveIndex(index)}
                key={index}
              >
                {item}
              </button>
            ))}
          </div>
        </div>

        <div className={styles.wrap} style={{width: "100%"}}>
          <div
            className={cn(styles.products, {
              [styles.triple]: isNotSimple,
            })}
          >
            {/* Use filteredCreations instead of creations */}
            {filteredCreations.map((creation, index) => (
              <NewCreationCard
                className={styles.product}
                item={creation}
                key={index}
                onShowPreview={async () =>
                {
                  openModal(
                    filteredCreations, // pass the filtered list
                    index,
                    creation,
                    fetchArtifactAndCreations
                  )
                  // try {
                  //   // Fetch and resolve the artifact
                  //   const resolvedArtifact = await fetchArtifact(creation.artifact_id);
              
                  //   // Pass the resolved artifact to the modal
                  //   openModal(
                  //     filteredCreations, // Pass the filtered list
                  //     index,
                  //     resolvedArtifact, // Pass the resolved artifact
                  //     fetchArtifactAndCreations
                  //   );
                  // } catch (error) {
                  //   console.error("Error resolving artifact:", error);
                  //   toast.error("Failed to fetch artifact for preview.");
                  // }
                }}
                onLikeDislike={() =>
                  toggleLikeStatus(
                    creation?.creation_id,
                    creation?.creation_sub_id
                  )
                }
                onRegenerateClick={
                  artifactId && (creation.raw_prompt || creation.prompt)
                    ? () =>
                        regenerateCreation(
                          creation?.creation_id,
                          creation?.creation_sub_id
                        )
                    : null
                }
                onGenerateVariationsClick={
                  artifactId &&
                  (creation.raw_prompt || creation.prompt) &&
                  !(artifact?.use_flux !== false)
                    ? () =>
                        generateNewVariations(
                          creation?.creation_id,
                          creation?.creation_sub_id
                        )
                    : null
                }
              />
            ))}
          </div>

          <div className={styles.foot}>
            {hasMoreCreations && filteredCreations.length > 0 && (
              <button
                className={cn("button-stroke button-small", styles.button)}
                onClick={loadMoreCreations}
                disabled={loadingMore}
                style={{ marginTop: "20px" }}
              >
                {loadingMore ? "Loading..." : "Load more"}
              </button>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default NewGallery;
