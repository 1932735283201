import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import CustomTooltip from "../CustomTooltip";
import TooltipData from "../CustomTooltip/TooltipsData";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import stylesTooltip from "../CustomTooltip/CustomTooltip.module.sass";

const Dropdown = ({
  id,
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  disabledOptions = [], // New prop for disabled options
  label,
  small,
  upBody,
  style,
  showToolTip = false,
  showOptionTooltips = false,
  isDisabled = false,
}) => {
  const [visible, setVisible] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleClick = (selectedValue) => {
    if (!disabledOptions.includes(selectedValue)) {
      setValue(selectedValue);
      setVisible(false);
    }
  };

  const isScrollable = options.length > 5;

  const getFirstWord = (text) => {
    if (typeof text === "string") {
      return text.split(" ")[0];
    } else if (text?.label) {
      return text.label.split(" ")[0];
    }
    return "";
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {/* Add tooltip here if needed */}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }, 
          { [styles.disabled]: isDisabled}
        )}
        style={{ ...style, 
          opacity: isDisabled ? 0.5 : 1, // Dim the dropdown when disabled
          pointerEvents: isDisabled ? "none" : "auto", // Prevent interactions when disabled
        }}
      >
        {showToolTip ? (
          <CustomTooltip title={TooltipData[id]} placement="top">
            <div
              className={cn(styles.head, classDropdownHead)}
              onClick={() => !isDisabled && setVisible(!visible)}
            >
              <div className={styles.selection}>
                {typeof value === "object" && value.content ? (
                  <div style={{ paddingTop: 14 }}>{value.content}</div>
                ) : (
                  value
                )}
              </div>
            </div>
          </CustomTooltip>
        ) : (
          <div
            className={cn(styles.head, classDropdownHead)}
            onClick={() => !isDisabled && setVisible(!visible)}
          >
            <div className={styles.selection}>
              {typeof value === "object" && value.content ? (
                <div style={{ paddingTop: 14 }}>{value.content}</div>
              ) : (
                value
              )}
            </div>
          </div>
        )}
        {visible && (
          <div
            className={cn(styles.body, {
              [styles.bodyUp]: upBody,
              [styles.scrollable]: isScrollable,
            })}
          >
            {options.map((x, index) => {
              const firstWord = getFirstWord(x);
              const tooltipContent = TooltipData[firstWord];
              const isDisabled = disabledOptions.includes(x);

              return showOptionTooltips && tooltipContent ? (
                <div
                  key={index}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  className={cn(styles.option, {
                    [styles.selectioned]: x === value,
                    [styles.disabled]: isDisabled,
                  })}
                  onClick={() => !isDisabled && handleClick(x)}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "5px",
                      position: "absolute",
                    }}
                  >
                    <CustomTooltip
                      title={
                        <div>
                          <div
                            style={{
                              fontSize: "15px",
                              marginBottom: "10px",
                            }}
                          >
                            {tooltipContent.text}
                          </div>
                          <img
                            src={tooltipContent.imageUrl}
                            alt={firstWord}
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              maxHeight: "250px",
                              borderRadius: "8px",
                              margin: "0 auto",
                              display: "block",
                              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                            }}
                          />
                        </div>
                      }
                      placement="left"
                      classes={{
                        tooltip: stylesTooltip["referencesOptionsTooltip"],
                      }}
                    >
                      <HelpOutlineIcon
                        style={{
                          fontSize: "17px",
                          visibility:
                            hoveredIndex === index ? "visible" : "hidden",
                          opacity: hoveredIndex === index ? 1 : 0,
                          transition:
                            "opacity 0.08s ease-in-out, visibility 0.1s ease-in-out",
                          color: "#5c5c5d",
                        }}
                      />
                    </CustomTooltip>
                  </div>

                  <div style={{ flexGrow: 1 }}>
                    {typeof x === "object" && x.content ? (
                      <>
                        <div>{x.label}</div>
                        {x.content}
                      </>
                    ) : (
                      x
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className={cn(styles.option, {
                    [styles.selectioned]: x === value,
                    [styles.disabled]: isDisabled,
                  })}
                  onClick={() => !isDisabled && handleClick(x)}
                  key={index}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div style={{ flexGrow: 1 }}>
                    {typeof x === "object" && x.content ? (
                      <>
                        <div>{x.label}</div>
                        {x.content}
                      </>
                    ) : (
                      x
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
