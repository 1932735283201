import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import styles from "./StickerGallery.module.sass";

const StickersGallery = ({ onStickerSelected, allStickers, organization }) => {
const [activeTab, setActiveTab] = useState("");
const [stickerCategories, setStickerCategories] = useState({});
// const hardcodedSticker = 
// {
//     "description": null,
//     "organization": "GenieLabs",
//     "sticker_id": "bcd4fc99-b6fa-4633-a12b-520c868e0554",
//     "sticker_name": "Coins",
//     "sticker_url": "/images/stickers/Coin.png",
// }
// allStickers.push(hardcodedSticker);
useEffect(() => {
    if (allStickers && Array.isArray(allStickers)) {
    // Group stickers into categories
    const myStickers = allStickers.filter(
        (sticker) => sticker.organization === organization
    );
    const genieStickers = allStickers.filter(
        (sticker) =>
        sticker.organization === null || sticker.organization === "GenieLabs"
    );

    // Create categories
    const groupedStickers = {
        "Studio Stickers": myStickers.map((sticker) => sticker.sticker_url),
        "Genie Stickers": genieStickers.map((sticker) => sticker.sticker_url),
    };

    setStickerCategories(groupedStickers);
    setActiveTab(Object.keys(groupedStickers)[0]); // Default to the first category
    }
}, [allStickers, organization]);

const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
};

useEffect(() => {
    console.log("StickersGallery: allStickers", allStickers);
    console.log("StickersGallery: organization", organization);
}, [allStickers, organization]);

return (
    <div
    style={{
        position: "absolute",
        width: 400,
        height: 400,
        right: "-47vw",
        left: "-47vw",
        borderRadius: "10px",
        boxSizing: "border-box",
        overflow: "hidden",
        transform: "translateY(19%)",
        zIndex: 1000,
    }}
    >
    <Box style={{ backgroundColor: "#ffffff", borderRadius: "inherit", height: "100%" }}>
        {/* Header */}
        <div
        style={{
            backgroundColor: "#dac4fc",
            padding: 7,
            width: "100%",
            textAlign: "center",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            boxSizing: "border-box",
            borderBottom: "2px solid #8a2be2",
        }}
        >
        <h2>Stickers</h2>
        <h5 style={{ fontWeight: "lighter" }}>
            Click on the sticker to add it to the image
        </h5>
        </div>

        {/* Tabs with horizontal scrolling */}
        {Object.keys(stickerCategories).length > 0 && (
        <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="secondary"
            textColor="secondary"
            style={{ borderBottom: "1px solid #8a2be2", width: "100%" }}
        >
            {Object.keys(stickerCategories).map((category) => (
            <Tab key={category} label={category} value={category} />
            ))}
        </Tabs>
        )}

        {/* Sticker Container with vertical scrolling */}
        <div
        className={styles.stickerContainer}
        style={{
            padding: 0,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            overflowY: "auto",
        }}
        >
        {stickerCategories[activeTab]?.map((url, i) => (
            <div key={i} style={{ margin: 3 }}>
            <img
                crossOrigin="anonymous"
                onClick={() => {
                onStickerSelected(url);
                console.log("STICKER selected url:", url);
                }}
                src={url}
                alt="sticker"
                style={{
                width: "48px",
                height: "48px",
                cursor: "pointer",
                }}
            />
            </div>
        ))}
        </div>
    </Box>
    </div>
);
};

export default StickersGallery;

