import { Routes, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import SignUp from "./screens/SignUp";
import UnifiedSignIn from "./screens/SignIn";
import CreationsGallery from "./screens/CreationsGallery";
import NewGallery from "./screens/NewGallery";
import LoadingBar from "react-top-loading-bar";
import { useLoadingBar } from "./contexts/LoadingBarContext";
import { DndContext } from "@dnd-kit/core";
import { useLocation } from "react-router-dom";
import MultiAssetsComposition from "./screens/MultiAssetsComposition";
import PageList from "./screens/PageList";
import RequireAuth from "./contexts/RequireAuth"; // Adjust the import path as needed
import React, { useState, useEffect } from "react";
import Albums from "./screens/Albums";
import ForgotPassword from "./screens/ForgotPassword";
import { version } from "./version";
import GenerateOfferScreen from "./screens/GenerateOfferScreen";
import GenerateOfferScreen2 from "./screens/GenerateOfferScreen2";
import GenerateOfferScreen3 from "./screens/GenerateOfferScreen3";
import GenerateOffer1Plus2Screen from "./screens/PO1+2";
import AddData from "./screens/AddData";

const Layout = ({ children }) => {
  const { progress, setProgress } = useLoadingBar();

  return (
    <div>
      <LoadingBar color="purple" progress={progress} style={{ height: 6 }} />
      {children}
    </div>
  );
};

function App() {
  const [userName, setUserName] = useState("");
  const location = useLocation();

  useEffect(() => {
    // Track pageview with Google Analytics on route change
    if (window.gtag && window.location.hostname == "dashboard.genielabs.co") {
      window.gtag("config", "G-26TNK0R141", {
        page_path: location.pathname,
      });
    }
  }, [location]);

  useEffect(() => {
    // Function to update the state with user's name from localStorage
    const loadUserName = () => {
      const storedUserName = localStorage.getItem("user"); // Adjust the key if needed
      if (storedUserName) {
        setUserName(storedUserName);
      }
    };

    // Call the function when the component mounts
    loadUserName();

    // Optional: Set up a listener for local storage changes
    // This is useful if the user info might change while the app is open
    window.addEventListener("storage", loadUserName);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("storage", loadUserName);
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<RequireAuth />}>
          <Route
            index
            element={
              <Page>
                <Home />
              </Page>
            }
          />
          <Route
            path="gallery/:creationType"
            element={
              <Page wide>
                <CreationsGallery />
              </Page>
            }
          />
          <Route
            path="gallery/"
            element={
              <Page wide>
                <CreationsGallery />
              </Page>
            }
          />
          <Route
            path="newGallery/"
            element={
              <Page wide>
                <NewGallery />
              </Page>
            }
          />
          <Route
            path="multi_assets_composition/:displayName/:artifactId"
            element={
              <Page wide>
                <MultiAssetsComposition />
              </Page>
            }
          />
          <Route
            path="/decoy"
            element={
              <Page wide>
                <DndContext>
                  <GenerateOfferScreen2 />
                </DndContext>
              </Page>
            }
          />
          <Route
            path="artifacts/:artifactId"
            element={
              <Page wide>
                <CreationsGallery />
              </Page>
            }
          />
          <Route
            path="triple-rolling-offer"
            element={
              <Page wide>
                <GenerateOfferScreen />
              </Page>
            }
          />
          <Route
            path="personal-offer-6"
            element={
              <Page wide>
                <GenerateOfferScreen />
              </Page>
            }
          />
          <Route
            path="personal-offer-1+2"
            element={
              <Page wide>
                <GenerateOffer1Plus2Screen />
              </Page>
            }
          />
          <Route
            path="albums/:artifactId"
            element={
              <Page wide>
                <Albums />
              </Page>
            }
          />
          <Route
            path="quests/:artifactId"
            element={
              <Page wide>
                <GenerateOfferScreen3 />
              </Page>
            }
          />
          <Route
            path="dice/:artifactId"
            element={
              <Page wide>
                <GenerateOfferScreen3 />
              </Page>
            }
          />
          <Route
            path="tournaments/:artifactId"
            element={
              <Page wide>
                <GenerateOfferScreen3 />
              </Page>
            }
          />
          <Route
            path="add_data"
            element={
              <Page wide>
                <AddData />
              </Page>
            }
          />
        </Route>
        {/* <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="sign-in" element={<UnifiedSignIn />} />
        <Route path="pagelist" element={<PageList />} /> */}
      </Routes>
      {
        <div style={{ position: "fixed", bottom: 0, left: 0 }}>
          Version: {version}
        </div>
      }
    </Layout>
  );
}

export default App;
