import React from "react";
import cn from "classnames";
import styles from "./Profile.module.sass";
import PromptBox from "../../../components/PromptBox";
import Card from "../../../components/Card/index";

const Profile = ({ artifact, generateCreations, error, canGenerate, baseCreation }) => {
  return (
  <Card className={styles.promptCard}>
    <div className={styles.profile} style={{ textAlign: "center" }}>
      <div className={styles.details} style={{ display: "flex", justifyContent: "center" }}>
        <div className={styles.wrap}>
          <h1 className={styles.displayName}>{artifact?.display_name}</h1>
          <PromptBox
            artifact={artifact}
            OnGenerateClicked={generateCreations}
            error={error}
            canGenerate={canGenerate}
            baseCreation={baseCreation}
          />
        </div>
      </div>
    </div>
  </Card>
  );
};

export default Profile;
