import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useParams, useLocation } from "react-router-dom";
import ApiService from "../../services/ApiService";
// Components
import Profile from "./Profile";
import Card from "../../components/Card";
import CreationCard from "../../components/Product";
import { toast } from "react-hot-toast";
import { useLoadingBar } from "../../contexts/LoadingBarContext";

import GenerationService from "../../services/GenerationService";
import { useModalPreview } from "../../contexts/ModalPreviewContext";
import { useAuth } from "../../contexts/AuthContext";

// Styles
import styles from "./CreationsGallery.module.sass";

const navigation = []; // Define your navigation items here

const complexComponents = ["triple-rolling-offer", "personal-offer-6"];

const CreationsGallery = () => {
  const { openModal } = useModalPreview();
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [hasMoreCreations, setHasMoreCreations] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [artifact, setArtifact] = useState(null);

  const [creations, setCreations] = useState([]);
  const { artifactId, creationType } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const artifactIdFromQuery = queryParams.get("artifact_id");

  const [loadingMore, setLoadingMore] = useState(false); // New state for "Load More" loading indicator
  const [currentViewingCreation, setCurrentViewingCreation] = useState(null);
  const [canGenerate, setCanGenerate] = useState(true);
  const isNotSimple = complexComponents.includes(creationType);
  const { progress, setProgress } = useLoadingBar();
  const { organizationData } = useAuth();

  const updateCreation = (creationId, creationSubId, updatedProperties) => {
    setCreations((prevCreations) =>
      prevCreations.map((creation) =>
        creation.creation_id === creationId &&
        creation.creation_sub_id === creationSubId
          ? { ...creation, ...updatedProperties }
          : creation
      )
    );
  };

  const findCreationIndex = (creationId, creationSubId) => {
    const index = creations.findIndex(
      (c) => c.creation_id === creationId && c.creation_sub_id == creationSubId
    );
    if (index === -1) {
      toast.error("Creation not found. Please try again later.");
      return null;
    }
    return index;
  };
  const findCreation = (creationId, creationSubId) => {
    const index = findCreationIndex(creationId, creationSubId);
    return index !== -1 ? creations[index] : null;
  };

  const regenerateCreation = async (creationId, creationSubId) => {
    await GenerationService.RegenerateCreation(
      findCreation(creationId, creationSubId)
    );
    fetchArtifactAndCreations();
  };

  const generateNewVariations = async (creationId, creationSubId) => {
    await GenerationService.GenerateNewVariations(
      findCreation(creationId, creationSubId)
    );
    fetchArtifactAndCreations();
  };

  const toggleLikeStatus = (creationId, creationSubId) => {
    const index = findCreationIndex(creationId, creationSubId);
    if (index === null) return;

    const updatedStatus = !creations[index].liked;

    toast
      .promise(
        ApiService.post(
          `/like_or_dislike_creation/${creationId}/${creationSubId}`,
          { liked: updatedStatus }
        ),
        {
          loading: "Updating like status...",
          success: () => {
            updateCreation(creationId, creationSubId, { liked: updatedStatus });
            return updatedStatus
              ? "Liked successfully!"
              : "Unliked successfully!";
          },
          error: "Failed to update like status. Please try again later.",
        }
      )
      .catch((error) => {
        console.error(error);
      });
  };

  const generateCreations = ({
    prompt,
    numImagesPerPrompt,
    isRemoveBackground,
    enrichPromptByChatGPTFlag,
    creationId = null,
    image,
    referenceType,
    dimensionsRatio,
    settings,
    masterColor,
    isLayout
  }) => {
    const timestamp = Date.now();
    setCanGenerate(false);

    toast.promise(
      (async () => {
        const response = await GenerationService.startGeneration({
          prompt: prompt,
          numberOfImages: numImagesPerPrompt,
          isRemoveBackground,
          enrichPromptByChatGPTFlag,
          creationId,
          image,
          referenceType,
          dimensionsRatio,
          artifact,
          artifactId,
          settings,
          masterColor,
          isLayout
        });

        if (response.error) {
          throw new Error(response.error);
        }

        await fetchArtifactAndCreations();
        return response;
      })(),
      {
        loading: "Generating creations...",
        success: () => {
          setCanGenerate(true);
          return "Creations generated successfully!";
        },
        error: (err) => {
          console.error("Failed to initiate generation:", err);
          setCreations((prevCreations) =>
            prevCreations.filter(
              (creation) =>
                !creation.creation_id.startsWith("temp_" + timestamp)
            )
          );
          setCanGenerate(true);
          return "Failed to initiate generation. Please try again later.";
        },
      }
    );
  };

  useEffect(() => {
    //cleanup
    setArtifact(null);
    setCreations([]);
    fetchArtifactAndCreations(null, true);
  }, [artifactId, creationType]);

  useEffect(() => {
    if (currentViewingCreation != null) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [currentViewingCreation]);

  const loadMoreCreations = async () => {
    if (lastEvaluatedKey && !loadingMore) {
      setLoadingMore(true);
      setProgress(50);
      try {
        console.log("Loading more creations...");
        // Pass `true` for the `append` parameter to ensure new creations are added to the end
        await fetchArtifactAndCreations(lastEvaluatedKey, true);
      } catch (error) {
        toast.error("Failed to load more creations, please try again later.");
        console.error("Error loading more creations:", error);
      } finally {
        setLoadingMore(false);
        setProgress(100);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkStatusOfPendingCreations();
    }, 10000);

    return () => clearInterval(interval);
  }, [creations]);

  const checkStatusOfPendingCreations = async () => {
    const pendingCreations = creations?.filter(
      (creation) => creation.status === "pending"
    );
    if (!pendingCreations || pendingCreations.length === 0) {
      return;
    }

    const payload = pendingCreations.map((creation) => ({
      creation_id: creation.creation_id,
      creation_sub_id: creation.creation_sub_id,
    }));

    try {
      const response = await ApiService.post("/check_status", payload);
      console.log(response);
      const updatedCreations = response.results;

      setCreations((prevCreations) =>
        prevCreations.map((c) => {
          const updatedCreation = updatedCreations.find(
            (uc) =>
              uc.creation_id === c.creation_id &&
              uc.creation_sub_id === c.creation_sub_id
          );
          return updatedCreation && !updatedCreation.error
            ? { ...c, ...updatedCreation.data }
            : c;
        })
      );
    } catch (error) {
      console.error("Error checking status:", error);
    }
  };
  
  const fetchArtifactAndCreations = async (
    startKey = null,
    append = false // New parameter to control whether to append or prepend
  ) => {
    const ARTIFACT_ENDPOINT = `/get_artifact/${artifactId}`;
    const CREATIONS_BASED_ON_ARTIFACT_ENDPOINT = `/get_creations_based_on_artifact_id`;
    const LIKED_CREATIONS_ENDPOINT = `/get_liked_creations`;

    const handleError = (message) => {
      toast.error(message);
      setProgress(100);
    };

    const buildUrlWithStartKey = (base, params = {}) => {
      let url = base;
      const queryParams = new URLSearchParams(params);
      if (startKey) queryParams.append("start_key", startKey);
      if (queryParams.toString()) url += `?${queryParams.toString()}`;
      return url;
    };

    const fetchCreations = async (artifact, creationType, append = false) => {
      let url;
      if (artifact) {
        url = buildUrlWithStartKey(CREATIONS_BASED_ON_ARTIFACT_ENDPOINT, {
          artifact_id: artifactId,
          limit: 20,
        });
      } else if (creationType) {
        url = buildUrlWithStartKey(LIKED_CREATIONS_ENDPOINT, {
          creation_type: creationType,
        });
      } else if (artifactIdFromQuery) {
        url = buildUrlWithStartKey(LIKED_CREATIONS_ENDPOINT, {
          artifact_id: artifactIdFromQuery,
        });
      } else {
        url = buildUrlWithStartKey(LIKED_CREATIONS_ENDPOINT);
      }

      try {
        const response = await ApiService.get(url);
        
        const newCreations = response.data;
        setLastEvaluatedKey(response.next_start_key);
        setHasMoreCreations(!!response.next_start_key);

        setCreations((prevCreations) => {
          // Create a copy of previous creations
          let updatedCreations = [...prevCreations];
          let newUniqueCreations = [];

          // Separate existing creations and update them with new data
          newCreations.forEach((newCreation) => {
            const existingIndex = updatedCreations.findIndex(
              (creation) =>
                creation.creation_id === newCreation.creation_id &&
                creation.creation_sub_id === newCreation.creation_sub_id
            );

            if (existingIndex !== -1) {
              // Update existing creation
              updatedCreations[existingIndex] = {
                ...updatedCreations[existingIndex],
                ...newCreation,
              };
            } else {
              newUniqueCreations.push(newCreation);
            }
          });

          // Handle non-existing (unique) creations: append or prepend them
          newUniqueCreations.forEach((newCreation) => {
            if (append) {
              updatedCreations.push(newCreation); // Append to the end
            } else {
              updatedCreations.unshift(newCreation); // Prepend to the beginning
            }
          });

          // Return the final list with updated and new creations
          return updatedCreations;
        });
      } catch (error) {
        console.error("Error fetching creations:", error);
        handleError("Failed to fetch creations, please try again later.");
      }
    };
    try {
      if (artifactId) {
        //Asset Screen
        const artifactResponse = await ApiService.get(ARTIFACT_ENDPOINT);
        setProgress(60);
        setArtifact(artifactResponse.data.artifact);

        if (artifactResponse.data.artifact) {
          await fetchCreations(artifactResponse.data.artifact, null, append);
        } else {
          setCreations([]);
        }
      } else {
        //Gallery
        await fetchCreations(null, creationType, append);
      }
    } catch (error) {
      handleError("Failed to fetch artifact, please try again later.");
    } finally {
      setProgress(100);
    }
  };

  return (
    <div className={`${styles.shop}`}>
      <div className={styles.background}>
        <img src={organizationData?.app_bg} alt="Background" />
      </div>
      <Card className={styles.card}>
        {artifact && (
          <Profile
            artifact={artifact}
            generateCreations={generateCreations}
            canGenerate={canGenerate}
          />
        )}
        {creations == [] && (
          <div>
            <h1>It looks like you haven't created anything yet</h1>
            <h2>
              Choose one of the generators from the navigation bar at the left
              to begin!
            </h2>
          </div>
        )}
        <div className={styles.control}>
          <div className={styles.nav}>
            {navigation.map((item, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: index === activeIndex,
                })}
                onClick={() => setActiveIndex(index)}
                key={index}
              >
                {item}
              </button>
            ))}
          </div>
        </div>
        <div className={styles.wrap}>
          <div
            className={cn(styles.products, {
              [styles.triple]: isNotSimple,
            })}
          >
            {creations?.map((creation, index) => (
              <CreationCard
                className={styles.product}
                item={creation}
                key={index}
                onShowPreview={() =>
                  openModal(
                    creations,
                    index,
                    artifact,
                    fetchArtifactAndCreations
                  )
                }
                onLikeDislike={() =>
                  toggleLikeStatus(
                    creation.creation_id,
                    creation.creation_sub_id
                  )
                }
                onRegenerateClick={
                  artifactId && (creation.raw_prompt || creation.prompt)
                    ? () =>
                        regenerateCreation(
                          creation.creation_id,
                          creation.creation_sub_id
                        )
                    : null
                }
                onGenerateVariationsClick={
                  artifactId &&
                  (creation.raw_prompt || creation.prompt) &&
                  !(artifact?.use_flux !== false)
                    ? () =>
                        generateNewVariations(
                          creation.creation_id,
                          creation.creation_sub_id
                        )
                    : null
                }
              />
            ))}
          </div>
          <div className={styles.foot}>
            {hasMoreCreations && creations.length > 0 && (
              <button
                className={cn("button-stroke button-small", styles.button)}
                onClick={loadMoreCreations}
                disabled={loadingMore}
              >
                {loadingMore ? "Loading..." : "Load more"}
              </button>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CreationsGallery;
