import { toast } from "react-hot-toast";
import ApiService from "./services/ApiService";

// Utility function to update download status via API
export const updateDownloaded = async (creationId, creationSubId) => {
  try {
    await ApiService.post(`/download_creation/${creationId}/${creationSubId}`);
  } catch (error) {
    console.error("Failed to update download status:", error);
  }
};

// Utility function to format numbers with commas
export const numberWithCommas = (x) => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

// Utility function to generate a random progress percentage
export const progress = () => {
  return Math.floor(Math.random() * 90) + 10 + "%";
};

// Utility function to convert image to base64
export const convertImageToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result.split(",")[1]);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

// Utility function to format a timestamp to local time
export const formatTimestampToLocalTime = (ns) => {
  const ms = ns / 1e6;
  const date = new Date(ms);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  return `${hours}:${minutes} ${day}/${month}/${year}`;
};

// Utility function to download an image
export const downloadImage = (imageUrl, fileName) => {
  const link = document.createElement("a");
  link.href = imageUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Error messages for password validation
export const errorMessages = {
  leadingSpaces: "- Should not have leading spaces.",
  trailingSpaces: "- Should not have trailing spaces.",
  uppercase: "- Contain at least one uppercase letter.",
  lowercase: "- Contain at least one lowercase letter.",
  digit: "- Contain at least one digit.",
  specialCharacter: "- Contain at least one special character.",
  length: "- Length should be between 8 and 256 characters.",
};

// Utility function to validate passwords
export const validatePassword = (password) => {
  const results = [];
  if (/^\s+/.test(password)) results.push(errorMessages.leadingSpaces);
  if (/\s+$/.test(password)) results.push(errorMessages.trailingSpaces);
  if (!/[A-Z]/.test(password)) results.push(errorMessages.uppercase);
  if (!/[a-z]/.test(password)) results.push(errorMessages.lowercase);
  if (!/[0-9]/.test(password)) results.push(errorMessages.digit);
  if (!/[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\-]/.test(password)) {
    results.push(errorMessages.specialCharacter);
  }
  if (password.length < 8 || password.length > 256) {
    results.push(errorMessages.length);
  }

  if (results.length > 0) {
    toast.error(`Password is invalid!\n${results.join("\n")}`, {
      autoClose: 5000 + results.length * 1000,
    });
    return false;
  }
  return true;
};

// Utility function to validate a reset code
export const validateResetCode = (code) => {
  if (!/^\d{6}$/.test(code)) {
    toast.error(
      `An email has been sent to you with a 6-digit verification code. Please enter the code exactly as it appears in the email`,
      { autoClose: 7000 }
    );
    return false;
  }
  return true;
};

// Utility function to convert strings containing numbers into actual numbers
export const convertStringNumbersToNumbers = (settings) => {
  const result = {};
  for (const key in settings) {
    if (typeof settings[key] === "string" && !isNaN(settings[key])) {
      result[key] = Number(settings[key]);
    } else if (typeof settings[key] === "object" && settings[key] !== null) {
      result[key] = convertStringNumbersToNumbers(settings[key]);
    } else {
      result[key] = settings[key];
    }
  }
  return result;
};

export function translateTimestamp(nanosecondsTimestamp) {
  if (typeof nanosecondsTimestamp !== "number" || isNaN(nanosecondsTimestamp)) {
    console.log("nanosecondsTimestamp", nanosecondsTimestamp);
    return "";
    throw new Error("Invalid nanosecondsTimestamp: must be a number.");
  }

  // Convert nanoseconds → milliseconds
  const milliseconds = nanosecondsTimestamp / 1e6; // 1e6 = 1000000
  const date = new Date(milliseconds);

  if (isNaN(date.getTime())) {
    throw new Error("Invalid date: the provided timestamp does not translate to a valid date.");
  }
  return date; // Return a real Date object
  // return date;
}

export function calculateDaysDifference(date1, date2) {
  // Format the dates first
  date1 = formatDate(new Date(date1));
  date2 = formatDate(new Date(date2));

  const parsedDate1 = new Date(date1.split('/').reverse().join('-'));
  const parsedDate2 = new Date(date2.split('/').reverse().join('-'));

  if (!(parsedDate1 instanceof Date) || isNaN(parsedDate1) ||
      !(parsedDate2 instanceof Date) || isNaN(parsedDate2)) {
    throw new Error("Both inputs must be valid Date objects.");
  }

  // time difference in ms
  const diffMs = parsedDate2.getTime() - parsedDate1.getTime();
  // convert ms → days (float)
  const diffDays = diffMs / (1000 * 60 * 60 * 24);
  return diffDays;
}

export const formatDate = (date, format = "dd/mm/yyyy") => {
  if (!(date instanceof Date)) {
    throw new Error("Input must be a valid Date object.");
  }
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return format.replace("dd", day).replace("mm", month).replace("yyyy", year);
};

export const customFlattenObject = (obj) => {
  const flattened = {};

  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null && !Array.isArray(obj[key])) {
      // Preserve nested dictionaries
      flattened[key] = obj[key];
    } else {
      // Directly assign other fields (non-objects or arrays)
      flattened[key] = obj[key];
    }
  }
  console.log("flattenObject", flattened)
  return flattened;
}

export const parseNumericValues = (data) => {
  // Check if the input is not an object, return as-is
  if (typeof data !== "object" || data === null) {
    return data;
  }

  const parsedData = Array.isArray(data) ? [] : {};

  for (const key in data) {
    if (Object.hasOwn(data, key)) {
      const value = data[key];

      if (typeof value === "string" && /^\d/.test(value.trim())) {
        // Convert to int or float
        parsedData[key] = value.includes(".") ? parseFloat(value) : parseInt(value, 10);
      } else if (typeof value === "object" && value !== null) {
        // Recursively process nested objects or arrays
        parsedData[key] = parseNumericValues(value);
      } else {
        // Leave other values as-is
        parsedData[key] = value;
      }
    }
  }

  return parsedData;
}

