import React, { useState } from "react";
import cn from "classnames";
import styles from "./Product.module.sass";
import Control from "./Control";
import Loader from "../Loader";
import { toast } from "react-hot-toast";
import {
  TripleRollingOfferOption,
  PersonalOffer6Option,
} from "../../screens/GenerateOfferScreen";

const componentMapping = {
  "triple-rolling-offer": TripleRollingOfferOption,
  "personal-offer-6": PersonalOffer6Option,
};

const CreationCard = ({
  className,
  item,
  onShowPreview,
  onLikeDislike,
  onRegenerateClick,
  onGenerateVariationsClick,
  showPrompt = true,
  fixedSize,
  onSelectQuest,
  isAlbum,
  settings,
}) => {
  const [visible, setVisible] = useState(false);
  const OfferComponent = componentMapping[item?.creation_type];
  const isCompleted = item?.status === "completed";
  const handleClick = () => {
    if (onShowPreview && isCompleted) {
      onShowPreview(item);
    }
  };

  const copyToPromptBox = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(item?.prompt);
    toast.success("Prompt copied to clipboard");
  };

  // Use thumbnail if available, otherwise fallback to result image
  // const imageUrl = item?.thumbnail_image_url || item?.result_image_url;
  const imageUrl = Array.isArray(item?.thumbnail_image_url)
  ? item.thumbnail_image_url[0]
  : item?.thumbnail_image_url || 
    (Array.isArray(item?.result_image_url) ? item.result_image_url[0] : item?.result_image_url);

  return (
    <div
      className={cn(styles.product, className, {
        [styles.active]: visible,
        [styles.tripleRollingOffer]: OfferComponent,
      })}
      // style={fixedSize ? { width: fixedSize, height: fixedSize } : {}}
    >
      <div
        className={cn(styles.preview, {
          [styles.centerContent]: !imageUrl && !item?.is_place_holder,
          [styles.loading]: !imageUrl && !item?.is_place_holder,
          [styles.placeHolder]: item?.is_place_holder,
        })}
        style={{ height: fixedSize, width: fixedSize, marginTop: -16 }}
        onClick={handleClick}
      >
        {item?.is_place_holder ? (
          <div className={styles.grayRectangle}></div>
        ) : imageUrl ? (
          OfferComponent ? (
            <OfferComponent creation={item} />
          ) : (
            <div
              style={
                !isAlbum
                  ? {
                      backgroundImage: `url(${imageUrl})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      width: "100%",
                      height: "100%",
                    }
                  : {}
              }
              className={styles.imagePreview}
              aria-label={item?.display_name}
            >
              {isAlbum && (
                <img
                  src={item?.result_image_url}
                  alt={item?.display_name}
                  style={{
                    height: settings.height - 20,
                    width: settings.width,
                    borderRadius: "20px",
                    // objectFit: 'contain',
                  }}
                />
              )}

              <Control
                className={styles.control}
                item={item}
                onSelectQuest={
                  onSelectQuest && (() => onSelectQuest(item?.creation_id))
                }
                onLikeDislike={
                  onLikeDislike && (() => onLikeDislike(item?.creation_id))
                }
                onRegenerateClick={
                  onRegenerateClick &&
                  (() => onRegenerateClick(item?.creation_id))
                }
                onGenerateVariationsClick={
                  onGenerateVariationsClick &&
                  (() => onGenerateVariationsClick(item?.creation_id))
                }
                isLiked={item?.isLiked}
              />
            </div>
          )
        ) : (
          // console.log('item:', item),
          <Loader timestamp_creation_id={(item?.timestamp_creation_id?.split("_")[0])} time_of_click={(Date.now() / 1000)} />

        )}
      </div>
      {showPrompt && (
        <div
          className={styles.line}
          style={{
            backgroundColor: "#fafafa",
            padding: 4,
            border: "1px solid #ddd",
            fontSize: 12,
            textAlign: "center",
            borderRadius: "0 0 10px 10px",
          }}
          onClick={copyToPromptBox}
        >
          {item?.prompt}
        </div>
      )}
    </div>
  );
};

export default CreationCard;
