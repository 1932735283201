import React, { useState } from "react";
import cn from "classnames";
import styles from "./newCreationCard.module.sass";
import Control from "./Control";
import Loader from "../Loader";
import { toast } from "react-hot-toast";
import {
  TripleRollingOfferOption,
  PersonalOffer6Option,
} from "../../screens/GenerateOfferScreen";
import {translateTimestamp, formatDate} from "../../utils";

const componentMapping = {
  "triple-rolling-offer": TripleRollingOfferOption,
  "personal-offer-6": PersonalOffer6Option,
};

const NewCreationCard = ({
  className,
  item,
  onShowPreview,
  onLikeDislike,
  onRegenerateClick,
  onGenerateVariationsClick,
  showPrompt = true,
  fixedSize,
  onSelectQuest,
  isAlbum,
  settings,
}) => {
  const [visible, setVisible] = useState(false);
  const OfferComponent = componentMapping[item?.creation_type];
  const isCompleted = item?.status === "completed";
  // console.log("item", item);
  const handleClick = () => {
    if (onShowPreview && isCompleted) {
      onShowPreview(item);
    }
  };

  const copyToPromptBox = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(item?.prompt);
    toast.success("Prompt copied to clipboard");
  };

  const imageUrl = Array.isArray(item?.thumbnail_image_url)
  ? item.thumbnail_image_url[0]
  : item?.thumbnail_image_url || 
    (Array.isArray(item?.result_image_url) ? item.result_image_url[0] : item?.result_image_url);


    return (
      <div
        className={cn(className, {
          [styles.active]: visible,
          [styles.tripleRollingOffer]: OfferComponent,
        })}
        style={{
          backgroundColor: "#f6f1fe",
          borderRadius: "12px", // Rounded corners
          padding: "4px", // Padding for the container
          // width: "300px", // Card width
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for better visibility
          overflow: "hidden"
        }}
      >
        <div
          className={cn(styles.preview, {
            [styles.centerContent]: !imageUrl && !item?.is_place_holder,
            [styles.loading]: !imageUrl && !item?.is_place_holder,
            [styles.placeHolder]: item?.is_place_holder,
          })}
          style={{
            // height: "250px", // Fixed height for the image
            aspectRatio: "1 / 1", // Aspect ratio for the image
            width: "100%",
            borderRadius: "12px", // Rounded corners for the image
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            marginBottom: "12px", // Space below the image
          }}
          onClick={handleClick}
        >

          {item?.is_place_holder && <div className={styles.grayRectangle}></div>}
          {/* <Control
                className={styles.control}
                item={item}
                onSelectQuest={
                  onSelectQuest && (() => onSelectQuest(item?.creation_id))
                }
                onLikeDislike={
                  onLikeDislike && (() => onLikeDislike(item?.creation_id))
                }
                onRegenerateClick={
                  onRegenerateClick &&
                  (() => onRegenerateClick(item?.creation_id))
                }
                onGenerateVariationsClick={
                  onGenerateVariationsClick &&
                  (() => onGenerateVariationsClick(item?.creation_id))
                }
                isLiked={item?.isLiked}
              /> */}
        </div>
        <div
          className={styles.line}
          style={{
            textAlign: "left", // Align text to the left
            fontSize: "14px",
            color: "#333", // Neutral text color
            flexDirection: "column"
          }}
          // onClick={copyToPromptBox}
        >
          <p style={{ margin: "0 5px -2px", backgroundColor: "#f6f1fe", fontSize: "18px", fontWeight: "bold"}}>{item?.artifact_display_name}</p>
          <p style={{ margin: "0 5px 6px", backgroundColor: "#f6f1fe", fontSize: "16px", fontWeight: "lighter"}}>
          {item?.creator_name} | {formatDate(translateTimestamp(parseInt(item?.timestamp || item?.timestamp_creation_id?.split('_')[0], 10)))}
            {/* {translateTimestamp(parseInt(item?.timestamp, 10)).toLocaleTimeString()} | {" "} */}
            {/* {translateTimestamp(parseInt(item?.timestamp, 10)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })} */}
            </p>
        </div>
      </div>
    );
    
};

export default NewCreationCard;
